import ReducerRegistry from '../base/redux/ReducerRegistry';
import { SET_SCREENSHARE_CAPTURE_FRAME_RATE, SET_SCREENSHARE_TRACKS, SET_SCREEN_AUDIO_SHARE_STATE } from './actionTypes';
const DEFAULT_STATE = {
    captureFrameRate: 30
};
/**
 * Reduces the Redux actions of the feature features/screen-share.
 */
ReducerRegistry.register('features/screen-share', (state = DEFAULT_STATE, action) => {
    const { captureFrameRate, isSharingAudio, desktopAudioTrack } = action;
    switch (action.type) {
        case SET_SCREEN_AUDIO_SHARE_STATE:
            return {
                ...state,
                isSharingAudio
            };
        case SET_SCREENSHARE_CAPTURE_FRAME_RATE:
            return {
                ...state,
                captureFrameRate
            };
        case SET_SCREENSHARE_TRACKS:
            return {
                ...state,
                desktopAudioTrack
            };
        default:
            return state;
    }
});
