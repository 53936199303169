import { Component } from 'react';
/**
 * Abstract class for the {@code VideoQualityLabel} component.
 */
export default class AbstractVideoQualityLabel extends Component {
}
/**
 * Maps (parts of) the Redux state to the associated
 * {@code AbstractVideoQualityLabel}'s props.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {{
 *     _audioOnly: boolean
 * }}
 */
export function _abstractMapStateToProps(state) {
    const { enabled: audioOnly } = state['features/base/audio-only'];
    return {
        _audioOnly: audioOnly
    };
}
