import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import { IconScreenshare, IconStopScreenshare } from '../../../base/icons/svg';
import JitsiMeetJS from '../../../base/lib-jitsi-meet/_';
import AbstractButton from '../../../base/toolbox/components/AbstractButton';
import { isScreenVideoShared } from '../../../screen-share/functions';
import { isDesktopShareButtonDisabled } from '../../functions';
/**
 * Implementation of a button for sharing desktop / windows.
 */
class ShareDesktopButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.shareYourScreen';
        this.toggledAccessibilityLabel = 'toolbar.accessibilityLabel.stopScreenSharing';
        this.label = 'toolbar.startScreenSharing';
        this.icon = IconScreenshare;
        this.toggledIcon = IconStopScreenshare;
        this.toggledLabel = 'toolbar.stopScreenSharing';
    }
    /**
     * Retrieves tooltip dynamically.
     *
     * @returns {string}
     */
    _getTooltip() {
        const { _desktopSharingEnabled, _screensharing } = this.props;
        if (_desktopSharingEnabled) {
            if (_screensharing) {
                return 'toolbar.stopScreenSharing';
            }
            return 'toolbar.startScreenSharing';
        }
        return 'dialog.shareYourScreenDisabled';
    }
    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props._screensharing;
    }
    /**
     * Indicates whether this button is in disabled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isDisabled() {
        return !this.props._desktopSharingEnabled;
    }
}
/**
 * Function that maps parts of Redux state tree into component props.
*
 * @param {Object} state - Redux state.
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    // Disable the screenshare button if the video sender limit is reached and there is no video or media share in
    // progress.
    const desktopSharingEnabled = JitsiMeetJS.isDesktopSharingEnabled() && !isDesktopShareButtonDisabled(state);
    return {
        _desktopSharingEnabled: desktopSharingEnabled,
        _screensharing: isScreenVideoShared(state)
    };
};
export default translate(connect(mapStateToProps)(ShareDesktopButton));
