import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import { IconInfoCircle } from '../../../base/icons/svg';
import ContextMenuItem from '../../../base/ui/components/web/ContextMenuItem';
import { renderConnectionStatus } from '../../actions.web';
const ConnectionStatusButton = ({ dispatch, t }) => {
    const onClick = useCallback(e => {
        e.stopPropagation();
        dispatch(renderConnectionStatus(true));
    }, [dispatch]);
    return (React.createElement(ContextMenuItem, { accessibilityLabel: t('videothumbnail.connectionInfo'), icon: IconInfoCircle, onClick: onClick, text: t('videothumbnail.connectionInfo') }));
};
export default translate(connect()(ConnectionStatusButton));
