import { openDialog } from '../../base/dialog/actions';
import { IconUserDeleted } from '../../base/icons/svg';
import AbstractButton from '../../base/toolbox/components/AbstractButton';
import { KickRemoteParticipantDialog } from './';
/**
 * An abstract remote video menu button which kicks the remote participant.
 */
export default class AbstractKickButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.kick';
        this.icon = IconUserDeleted;
        this.label = 'videothumbnail.kick';
    }
    /**
     * Handles clicking / pressing the button, and kicks the participant.
     *
     * @private
     * @returns {void}
     */
    _handleClick() {
        const { dispatch, participantID } = this.props;
        dispatch(openDialog(KickRemoteParticipantDialog, { participantID }));
    }
}
