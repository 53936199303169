import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../../base/i18n/functions';
import Dialog from '../../../../base/ui/components/web/Dialog';
import { toggleScreenshotCaptureSummary } from '../../../../screenshot-capture/actions';
import AbstractStopRecordingDialog, { _mapStateToProps } from '../AbstractStopRecordingDialog';
/**
 * React Component for getting confirmation to stop a file recording session in
 * progress.
 *
 * @augments Component
 */
class StopRecordingDialog extends AbstractStopRecordingDialog {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { t, localRecordingVideoStop } = this.props;
        return (React.createElement(Dialog, { title: '\u786E\u5B9A\u8981\u505C\u6B62\u5F55\u5236\uFF1F', ok: { translationKey: '停止' }, onSubmit: this._onSubmit, titleKey: 'dialog.recording' }, "\u5F55\u5236\u6587\u4EF6\uFF0C\u5C06\u5728\u5728\u8292PC-\u4F1A\u8BAE\u5F55\u5C4F\u56DE\u653E\u9875\u9762\u67E5\u770B"));
    }
    /**
     * Toggles screenshot capture.
     *
     * @returns {void}
     */
    _toggleScreenshotCapture() {
        this.props.dispatch(toggleScreenshotCaptureSummary(false));
    }
}
export default translate(connect(_mapStateToProps)(StopRecordingDialog));
