import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { createToolbarEvent } from '../../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../../analytics/functions';
import Popover from '../../../base/popover/components/Popover.web';
import ContextMenu from '../../../base/ui/components/web/ContextMenu';
import ContextMenuItemGroup from '../../../base/ui/components/web/ContextMenuItemGroup';
import { setGifMenuVisibility } from '../../../gifs/actions';
import { isGifsMenuOpen } from '../../../gifs/functions.web';
import ReactionEmoji from '../../../reactions/components/web/ReactionEmoji';
import ReactionsMenu from '../../../reactions/components/web/ReactionsMenu';
import { GIFS_MENU_HEIGHT_IN_OVERFLOW_MENU, RAISE_HAND_ROW_HEIGHT, REACTIONS_MENU_HEIGHT_DRAWER, REACTIONS_MENU_HEIGHT_IN_OVERFLOW_MENU } from '../../../reactions/constants';
import { getReactionsQueue } from '../../../reactions/functions.any';
import { IReactionsMenuParent } from '../../../reactions/types';
import { DRAWER_MAX_HEIGHT } from '../../constants';
import { showOverflowDrawer } from '../../functions.web';
import Drawer from './Drawer';
import JitsiPortal from './JitsiPortal';
import OverflowToggleButton from './OverflowToggleButton';
const useStyles = makeStyles()((_theme, { reactionsMenuHeight, overflowDrawer }) => {
    return {
        overflowMenuDrawer: {
            overflow: 'hidden',
            height: `calc(${DRAWER_MAX_HEIGHT} - ${reactionsMenuHeight}px - 16px)`
        },
        contextMenu: {
            position: 'relative',
            right: 'auto',
            margin: 0,
            marginBottom: '8px',
            maxHeight: overflowDrawer ? undefined : 'calc(100vh - 100px)',
            paddingBottom: overflowDrawer ? undefined : 0,
            minWidth: '240px',
            overflow: 'hidden'
        },
        content: {
            position: 'relative',
            maxHeight: overflowDrawer
                ? `calc(100% - ${reactionsMenuHeight}px - 16px)` : `calc(100vh - 100px - ${reactionsMenuHeight}px)`,
            overflowY: 'auto'
        },
        footer: {
            position: 'relative',
            bottom: 0
        }
    };
});
const OverflowMenuButton = ({ buttons, isOpen, onToolboxEscKey, onVisibilityChange, showRaiseHandInReactionsMenu, showReactionsMenu }) => {
    const overflowDrawer = useSelector(showOverflowDrawer);
    const reactionsQueue = useSelector(getReactionsQueue);
    const isGiphyVisible = useSelector(isGifsMenuOpen);
    const dispatch = useDispatch();
    const onCloseDialog = useCallback(() => {
        onVisibilityChange(false);
        if (isGiphyVisible && !overflowDrawer) {
            dispatch(setGifMenuVisibility(false));
        }
    }, [onVisibilityChange, setGifMenuVisibility, isGiphyVisible, overflowDrawer, dispatch]);
    const onOpenDialog = useCallback(() => {
        onVisibilityChange(true);
    }, [onVisibilityChange]);
    const onEscClick = useCallback((event) => {
        if (event.key === 'Escape' && isOpen) {
            event.preventDefault();
            event.stopPropagation();
            onCloseDialog();
        }
    }, [onCloseDialog]);
    const toggleDialogVisibility = useCallback(() => {
        sendAnalytics(createToolbarEvent('overflow'));
        onVisibilityChange(!isOpen);
    }, [isOpen, onVisibilityChange]);
    const toolbarAccLabel = 'toolbar.accessibilityLabel.moreActionsMenu';
    const { t } = useTranslation();
    let reactionsMenuHeight = 0;
    if (showReactionsMenu) {
        reactionsMenuHeight = REACTIONS_MENU_HEIGHT_DRAWER;
        if (!overflowDrawer) {
            reactionsMenuHeight = REACTIONS_MENU_HEIGHT_IN_OVERFLOW_MENU;
        }
        if (!showRaiseHandInReactionsMenu) {
            reactionsMenuHeight -= RAISE_HAND_ROW_HEIGHT;
        }
        if (!overflowDrawer && isGiphyVisible) {
            reactionsMenuHeight += GIFS_MENU_HEIGHT_IN_OVERFLOW_MENU;
        }
    }
    const { classes } = useStyles({
        reactionsMenuHeight,
        overflowDrawer
    });
    const groupsJSX = buttons.map((buttonGroup) => (React.createElement(ContextMenuItemGroup, { key: `group-${buttonGroup[0].group}` }, buttonGroup.map(({ key, Content, ...rest }) => {
        const props = { ...rest };
        if (key !== 'reactions') {
            props.buttonKey = key;
            props.contextMenu = true;
            props.showLabel = true;
        }
        return (React.createElement(Content, { ...props, key: key }));
    }))));
    const overflowMenu = groupsJSX && (React.createElement(ContextMenu, { accessibilityLabel: t(toolbarAccLabel), className: classes.contextMenu, hidden: false, id: 'overflow-context-menu', inDrawer: overflowDrawer, onKeyDown: onToolboxEscKey },
        React.createElement("div", { className: classes.content }, groupsJSX),
        showReactionsMenu && (React.createElement("div", { className: classes.footer },
            React.createElement(ReactionsMenu, { parent: overflowDrawer ? IReactionsMenuParent.OverflowDrawer : IReactionsMenuParent.OverflowMenu, showRaisedHand: showRaiseHandInReactionsMenu })))));
    if (overflowDrawer) {
        return (React.createElement("div", { className: 'toolbox-button-wth-dialog context-menu' },
            React.createElement(React.Fragment, null,
                React.createElement(OverflowToggleButton, { handleClick: toggleDialogVisibility, isOpen: isOpen, onKeyDown: onEscClick }),
                React.createElement(JitsiPortal, null,
                    React.createElement(Drawer, { isOpen: isOpen, onClose: onCloseDialog },
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: classes.overflowMenuDrawer }, overflowMenu))),
                    showReactionsMenu && React.createElement("div", { className: 'reactions-animations-container' }, reactionsQueue.map(({ reaction, uid }, index) => (React.createElement(ReactionEmoji, { index: index, key: uid, reaction: reaction, uid: uid }))))))));
    }
    return (React.createElement("div", { className: 'toolbox-button-wth-dialog context-menu' },
        React.createElement(Popover, { content: overflowMenu, headingId: 'overflow-context-menu', onPopoverClose: onCloseDialog, onPopoverOpen: onOpenDialog, position: 'top', trigger: 'click', visible: isOpen },
            React.createElement(OverflowToggleButton, { isOpen: isOpen, onKeyDown: onEscClick })),
        showReactionsMenu && React.createElement("div", { className: 'reactions-animations-container' }, reactionsQueue.map(({ reaction, uid }, index) => (React.createElement(ReactionEmoji, { index: index, key: uid, reaction: reaction, uid: uid }))))));
};
export default OverflowMenuButton;
