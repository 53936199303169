import React, { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import Icon from '../../../icons/components/Icon';
import { IconUser } from '../../../icons/svg';
import { withPixelLineHeight } from '../../../styles/functions.web';
import { isIcon } from '../../functions';
import { PRESENCE_AVAILABLE_COLOR, PRESENCE_AWAY_COLOR, PRESENCE_BUSY_COLOR, PRESENCE_IDLE_COLOR } from '../styles';
const useStyles = makeStyles()(theme => {
    return {
        avatar: {
            backgroundColor: '#AAA',
            borderRadius: '50%',
            fontWeight: '600',
            color: theme.palette?.text01 || '#fff',
            ...withPixelLineHeight(theme.typography?.heading1 ?? {}),
            fontSize: 'inherit',
            objectFit: 'cover',
            textAlign: 'center',
            overflow: 'hidden',
            '&.avatar-small': {
                height: '28px !important',
                width: '28px !important'
            },
            '&.avatar-xsmall': {
                height: '16px !important',
                width: '16px !important'
            },
            '& .jitsi-icon': {
                transform: 'translateY(50%)'
            },
            '& .avatar-svg': {
                height: '100%',
                width: '100%'
            }
        },
        initialsContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        badge: {
            position: 'relative',
            '&.avatar-badge:after': {
                borderRadius: '50%',
                content: '""',
                display: 'block',
                height: '35%',
                position: 'absolute',
                bottom: 0,
                width: '35%'
            },
            '&.avatar-badge-available:after': {
                backgroundColor: PRESENCE_AVAILABLE_COLOR
            },
            '&.avatar-badge-away:after': {
                backgroundColor: PRESENCE_AWAY_COLOR
            },
            '&.avatar-badge-busy:after': {
                backgroundColor: PRESENCE_BUSY_COLOR
            },
            '&.avatar-badge-idle:after': {
                backgroundColor: PRESENCE_IDLE_COLOR
            }
        }
    };
});
const StatelessAvatar = ({ className, color, id, initials, onAvatarLoadError, onAvatarLoadErrorParams, size, status, testId, url, useCORS }) => {
    const { classes, cx } = useStyles();
    const _getAvatarStyle = (backgroundColor) => {
        return {
            background: backgroundColor || undefined,
            fontSize: size ? size * 0.4 : '180%',
            height: size || '100%',
            width: size || '100%'
        };
    };
    const _getAvatarClassName = (additional) => cx('avatar', additional, className, classes.avatar);
    const _getBadgeClassName = () => {
        if (status) {
            return cx('avatar-badge', `avatar-badge-${status}`, classes.badge);
        }
        return '';
    };
    const _onAvatarLoadError = useCallback(() => {
        if (typeof onAvatarLoadError === 'function') {
            onAvatarLoadError(onAvatarLoadErrorParams);
        }
    }, [onAvatarLoadError, onAvatarLoadErrorParams]);
    if (isIcon(url)) {
        return (React.createElement("div", { className: cx(_getAvatarClassName(), _getBadgeClassName()), "data-testid": testId, id: id, style: _getAvatarStyle(color) },
            React.createElement(Icon, { size: '50%', src: url })));
    }
    if (url) {
        return (React.createElement("div", { className: _getBadgeClassName() },
            React.createElement("img", { alt: 'avatar', className: _getAvatarClassName(), crossOrigin: useCORS ? '' : undefined, "data-testid": testId, id: id, onError: _onAvatarLoadError, src: url, style: _getAvatarStyle() })));
    }
    if (initials) {
        return (React.createElement("div", { className: cx(_getAvatarClassName(), _getBadgeClassName()), "data-testid": testId, id: id, style: _getAvatarStyle(color) },
            React.createElement("div", { className: classes.initialsContainer }, initials)));
    }
    // default avatar
    return (React.createElement("div", { className: cx(_getAvatarClassName('defaultAvatar'), _getBadgeClassName()), "data-testid": testId, id: id, style: _getAvatarStyle() },
        React.createElement(Icon, { size: '50%', src: IconUser })));
};
export default StatelessAvatar;
