import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IconCheck } from '../../../base/icons/svg';
import ContextMenuItem from '../../../base/ui/components/web/ContextMenuItem';
import { startVerification } from '../../../e2ee/actions';
const VerifyParticipantButton = ({ dispatch, participantID }) => {
    const { t } = useTranslation();
    const _handleClick = useCallback(() => {
        dispatch(startVerification(participantID));
    }, [participantID]);
    return (React.createElement(ContextMenuItem, { accessibilityLabel: t('videothumbnail.verify'), className: 'verifylink', icon: IconCheck, id: `verifylink_${participantID}`, 
        // eslint-disable-next-line react/jsx-handler-names
        onClick: _handleClick, text: t('videothumbnail.verify') }));
};
/**
 * Maps (parts of) the Redux state to the associated {@code RemoteVideoMenuTriggerButton}'s props.
 *
 * @param {Object} state - The Redux state.
 * @param {Object} ownProps - The own props of the component.
 * @private
 * @returns {IProps}
 */
function _mapStateToProps(state, ownProps) {
    const { participantID } = ownProps;
    return {
        _participantID: participantID
    };
}
export default connect(_mapStateToProps)(VerifyParticipantButton);
