import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { createDeepLinkingPageEvent } from '../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../analytics/functions';
import { getLegalUrls } from '../../base/config/functions.any';
import { isSupportedBrowser } from '../../base/environment/environment';
import { translate, translateToHTML } from '../../base/i18n/functions';
import { withPixelLineHeight } from '../../base/styles/functions.web';
import Button from '../../base/ui/components/web/Button';
import { BUTTON_TYPES } from '../../base/ui/constants.any';
import { openDesktopApp, openWebApp } from '../actions';
import { _TNS } from '../constants';
const useStyles = makeStyles()((theme) => {
    return {
        container: {
            background: '#1E1E1E',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            display: 'flex'
        },
        contentPane: {
            display: 'flex',
            flexDirection: 'column',
            background: theme.palette.ui01,
            border: `1px solid ${theme.palette.ui03}`,
            padding: 40,
            borderRadius: 16,
            maxWidth: 410,
            color: theme.palette.text01
        },
        logo: {
            marginBottom: 32
        },
        launchingMeetingLabel: {
            marginBottom: 16,
            ...withPixelLineHeight(theme.typography.heading4)
        },
        roomName: {
            marginBottom: 32,
            ...withPixelLineHeight(theme.typography.heading5)
        },
        descriptionLabel: {
            marginBottom: 32,
            ...withPixelLineHeight(theme.typography.bodyLongRegular)
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            '& > *:not(:last-child)': {
                marginRight: 16
            }
        },
        separator: {
            marginTop: 40,
            height: 1,
            maxWidth: 390,
            background: theme.palette.ui03
        },
        label: {
            marginTop: 40,
            ...withPixelLineHeight(theme.typography.labelRegular),
            color: theme.palette.text02,
            '& a': {
                color: theme.palette.link01
            }
        }
    };
});
const DeepLinkingDesktopPage = ({ t }) => {
    const dispatch = useDispatch();
    const room = useSelector((state) => decodeURIComponent(state['features/base/conference'].room || ''));
    const deeplinkingCfg = useSelector((state) => state['features/base/config']?.deeplinking || {});
    const legalUrls = useSelector(getLegalUrls);
    const { hideLogo, desktop } = deeplinkingCfg;
    const { classes: styles } = useStyles();
    const onLaunchWeb = useCallback(() => {
        sendAnalytics(createDeepLinkingPageEvent('clicked', 'launchWebButton', { isMobileBrowser: false }));
        dispatch(openWebApp());
    }, []);
    const onTryAgain = useCallback(() => {
        sendAnalytics(createDeepLinkingPageEvent('clicked', 'tryAgainButton', { isMobileBrowser: false }));
        dispatch(openDesktopApp());
    }, []);
    useEffect(() => {
        sendAnalytics(createDeepLinkingPageEvent('displayed', 'DeepLinkingDesktop', { isMobileBrowser: false }));
    }, []);
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.contentPane },
            React.createElement("div", { className: 'header' }, !hideLogo
                && React.createElement("img", { alt: t('welcomepage.logo.logoDeepLinking'), className: styles.logo, src: 'images/logo-deep-linking.png' })),
            React.createElement("div", { className: styles.launchingMeetingLabel }, t(`${_TNS}.titleNew`)),
            React.createElement("div", { className: styles.roomName }, room),
            React.createElement("div", { className: styles.descriptionLabel }, isSupportedBrowser()
                ? translateToHTML(t, `${_TNS}.descriptionNew`, { app: desktop?.appName })
                : t(`${_TNS}.descriptionWithoutWeb`, { app: desktop?.appName })),
            React.createElement("div", { className: styles.buttonsContainer },
                React.createElement(Button, { label: t(`${_TNS}.tryAgainButton`), onClick: onTryAgain }),
                isSupportedBrowser() && (React.createElement(Button, { label: t(`${_TNS}.launchWebButton`), onClick: onLaunchWeb, type: BUTTON_TYPES.SECONDARY }))),
            React.createElement("div", { className: styles.separator }),
            React.createElement("div", { className: styles.label },
                " ",
                translateToHTML(t, 'deepLinking.termsAndConditions', {
                    termsAndConditionsLink: legalUrls.terms
                })))));
};
export default translate(DeepLinkingDesktopPage);
