import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { hideDialog } from '../../base/dialog/actions';
import { translate } from '../../base/i18n/functions';
import Dialog from '../../base/ui/components/web/Dialog';
import Tabs from '../../base/ui/components/web/Tabs';
import { obtainDesktopSources } from '../functions';
import DesktopPickerPane from './DesktopPickerPane';
/**
 * The size of the requested thumbnails.
 *
 * @type {Object}
 */
const THUMBNAIL_SIZE = {
    height: 300,
    width: 300
};
/**
 * The sources polling interval in ms.
 *
 * @type {int}
 */
const UPDATE_INTERVAL = 2000;
/**
 * The default selected tab.
 *
 * @type {string}
 */
const DEFAULT_TAB_TYPE = 'screen';
const TAB_LABELS = {
    screen: 'dialog.yourEntireScreen',
    window: 'dialog.applicationWindow'
};
const VALID_TYPES = Object.keys(TAB_LABELS);
/**
 * React component for DesktopPicker.
 *
 * @augments Component
 */
class DesktopPicker extends PureComponent {
    /**
     * Implements React's {@link Component#getDerivedStateFromProps()}.
     *
     * @inheritdoc
     */
    static getDerivedStateFromProps(props) {
        return {
            types: DesktopPicker._getValidTypes(props.desktopSharingSources)
        };
    }
    /**
     * Extracts only the valid types from the passed {@code types}.
     *
     * @param {Array<string>} types - The types to filter.
     * @private
     * @returns {Array<string>} The filtered types.
     */
    static _getValidTypes(types = []) {
        return types.filter(type => VALID_TYPES.includes(type));
    }
    /**
     * Initializes a new DesktopPicker instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);
        this._poller = null;
        this.state = {
            screenShareAudio: false,
            selectedSource: {},
            selectedTab: DEFAULT_TAB_TYPE,
            sources: {},
            types: []
        };
        /**
         * Stores the type of the selected tab.
         *
         * @type {string}
         */
        this._selectedTabType = DEFAULT_TAB_TYPE;
        // Bind event handlers so they are only bound once per instance.
        this._onCloseModal = this._onCloseModal.bind(this);
        this._onPreviewClick = this._onPreviewClick.bind(this);
        this._onShareAudioChecked = this._onShareAudioChecked.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onTabSelected = this._onTabSelected.bind(this);
        this._updateSources = this._updateSources.bind(this);
        this.state.types
            = DesktopPicker._getValidTypes(this.props.desktopSharingSources);
    }
    /**
     * Starts polling.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentDidMount() {
        this._startPolling();
    }
    /**
     * Clean up component and DesktopCapturerSource store state.
     *
     * @inheritdoc
     */
    componentWillUnmount() {
        this._stopPolling();
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     */
    render() {
        const { selectedTab, selectedSource, sources, types } = this.state;
        return (React.createElement(Dialog, { ok: {
                disabled: Boolean(!this.state.selectedSource.id),
                translationKey: 'dialog.Share'
            }, onCancel: this._onCloseModal, onSubmit: this._onSubmit, size: 'large', titleKey: 'dialog.shareYourScreen' },
            this._renderTabs(),
            types.map(type => (React.createElement("div", { "aria-labelledby": `${type}-button`, className: selectedTab === type ? undefined : 'hide', id: `${type}-panel`, key: type, role: 'tabpanel', tabIndex: 0 }, selectedTab === type && (React.createElement(DesktopPickerPane, { key: selectedTab, onClick: this._onPreviewClick, onDoubleClick: this._onSubmit, onShareAudioChecked: this._onShareAudioChecked, selectedSourceId: selectedSource.id, sources: sources[selectedTab], type: selectedTab })))))));
    }
    /**
     * Computes the selected source.
     *
     * @param {Object} sources - The available sources.
     * @returns {Object} The selectedSource value.
     */
    _getSelectedSource(sources = {}) {
        const { selectedSource } = this.state;
        /**
         * If there are no sources for this type (or no sources for any type)
         * we can't select anything.
         */
        if (!Array.isArray(sources[this._selectedTabType])
            || sources[this._selectedTabType].length <= 0) {
            return {};
        }
        /**
         * Select the first available source for this type in the following
         * scenarios:
         * 1) Nothing is yet selected.
         * 2) Tab change.
         * 3) The selected source is no longer available.
         */
        if (!selectedSource // scenario 1)
            || selectedSource.type !== this._selectedTabType // scenario 2)
            || !sources[this._selectedTabType].some(// scenario 3)
            (source) => source.id === selectedSource.id)) {
            return {
                id: sources[this._selectedTabType][0].id,
                type: this._selectedTabType
            };
        }
        /**
         * For all other scenarios don't change the selection.
         */
        return selectedSource;
    }
    /**
     * Dispatches an action to hide the DesktopPicker and invokes the passed in
     * callback with a selectedSource, if any.
     *
     * @param {string} [id] - The id of the DesktopCapturerSource to pass into
     * the onSourceChoose callback.
     * @param {string} type - The type of the DesktopCapturerSource to pass into
     * the onSourceChoose callback.
     * @param {boolean} screenShareAudio - Whether or not to add system audio to
     * screen sharing session.
     * @returns {void}
     */
    _onCloseModal(id = '', type, screenShareAudio = false) {
        this.props.onSourceChoose(id, type, screenShareAudio);
        this.props.dispatch(hideDialog());
    }
    /**
     * Sets the currently selected DesktopCapturerSource.
     *
     * @param {string} id - The id of DesktopCapturerSource.
     * @param {string} type - The type of DesktopCapturerSource.
     * @returns {void}
     */
    _onPreviewClick(id, type) {
        this.setState({
            selectedSource: {
                id,
                type
            }
        });
    }
    /**
     * Request to close the modal and execute callbacks with the selected source
     * id.
     *
     * @returns {void}
     */
    _onSubmit() {
        const { selectedSource: { id, type }, screenShareAudio } = this.state;
        this._onCloseModal(id, type, screenShareAudio);
    }
    /**
     * Stores the selected tab and updates the selected source via
     * {@code _getSelectedSource}.
     *
     * @param {string} id - The id of the newly selected tab.
     * @returns {void}
     */
    _onTabSelected(id) {
        const { sources } = this.state;
        this._selectedTabType = id;
        // When we change tabs also reset the screenShareAudio state so we don't
        // use the option from one tab when sharing from another.
        this.setState({
            screenShareAudio: false,
            selectedSource: this._getSelectedSource(sources),
            selectedTab: id
        });
    }
    /**
     * Set the screenSharingAudio state indicating whether or not to also share
     * system audio.
     *
     * @param {boolean} checked - Share audio or not.
     * @returns {void}
     */
    _onShareAudioChecked(checked) {
        this.setState({ screenShareAudio: checked });
    }
    /**
     * Configures and renders the tabs for display.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderTabs() {
        const { types } = this.state;
        const { t } = this.props;
        const tabs = types.map(type => {
            return {
                accessibilityLabel: t(TAB_LABELS[type]),
                id: `${type}`,
                controlsId: `${type}-panel`,
                label: t(TAB_LABELS[type])
            };
        });
        return (React.createElement(Tabs, { accessibilityLabel: t('dialog.sharingTabs'), className: 'desktop-picker-tabs-container', onChange: this._onTabSelected, selected: `${this.state.selectedTab}`, tabs: tabs }));
    }
    /**
     * Create an interval to update known available DesktopCapturerSources.
     *
     * @private
     * @returns {void}
     */
    _startPolling() {
        this._stopPolling();
        this._updateSources();
        this._poller = window.setInterval(this._updateSources, UPDATE_INTERVAL);
    }
    /**
     * Cancels the interval to update DesktopCapturerSources.
     *
     * @private
     * @returns {void}
     */
    _stopPolling() {
        window.clearInterval(this._poller);
        this._poller = null;
    }
    /**
     * Obtains the desktop sources and updates state with them.
     *
     * @private
     * @returns {void}
     */
    _updateSources() {
        const { types } = this.state;
        if (types.length > 0) {
            obtainDesktopSources(this.state.types, { thumbnailSize: THUMBNAIL_SIZE })
                .then((sources) => {
                const selectedSource = this._getSelectedSource(sources);
                // TODO: Maybe check if we have stopped the timer and unmounted
                // the component.
                this.setState({
                    sources,
                    selectedSource
                });
            })
                .catch(() => { });
        }
    }
}
export default translate(connect()(DesktopPicker));
