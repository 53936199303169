import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import CopyButton from '../../../../base/buttons/CopyButton.web';
const useStyles = makeStyles()(theme => {
    return {
        label: {
            display: 'block',
            marginBottom: theme.spacing(2)
        }
    };
});
/**
 * Component meant to enable users to copy the conference URL.
 *
 * @returns {React$Element<any>}
 */
function CopyMeetingLinkSection({ url, userName }) {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const { roomName } = APP.conference;
    const showLabel = `会议ID：${roomName}`;
    const env = /dev/.test(location.hostname) ? 'http://www.zaimang-test.net' : 'https://www.zaimang.net';
    const link = `${env}/app/m/${roomName}`;
    const name = sessionStorage.getItem('localMeetingName');
    const copyLabel = `${userName} 邀请您参加在芒会议 \n会议主题：${name} \n会议ID：${roomName} \n会议链接：${link}  \n\n点击会议链接或者复制该信息，打开在芒即可入会`;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: classes.label }, t('addPeople.shareLink')),
        React.createElement(CopyButton
        // accessibilityText = { t('addPeople.accessibilityLabel.meetingLink', { url: getDecodedURI(url) }) }
        , { 
            // accessibilityText = { t('addPeople.accessibilityLabel.meetingLink', { url: getDecodedURI(url) }) }
            accessibilityText: t('addPeople.accessibilityLabel.meetingLink', { url: showLabel }), className: 'invite-more-dialog-conference-url', 
            // displayedText = { getDecodedURI(url) }
            displayedText: showLabel, id: 'add-people-copy-link-button', textOnCopySuccess: t('addPeople.linkCopied'), textOnHover: t('addPeople.copyLink'), 
            // textToCopy = { url }
            textToCopy: copyLabel })));
}
export default CopyMeetingLinkSection;
