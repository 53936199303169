import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import { IconModerator } from '../../../base/icons/svg';
import ContextMenuItem from '../../../base/ui/components/web/ContextMenuItem';
import AbstractGrantModeratorButton, { _mapStateToProps } from '../AbstractGrantModeratorButton';
/**
 * Implements a React {@link Component} which displays a button for granting
 * moderator to a participant.
 */
class GrantModeratorButton extends AbstractGrantModeratorButton {
    /**
     * Instantiates a new {@code GrantModeratorButton}.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this._handleClick = this._handleClick.bind(this);
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { t, visible } = this.props;
        if (!visible) {
            return null;
        }
        return (React.createElement(ContextMenuItem, { accessibilityLabel: t('toolbar.accessibilityLabel.grantModerator'), className: 'grantmoderatorlink', icon: IconModerator, 
            // eslint-disable-next-line react/jsx-handler-names
            onClick: this._handleClick, text: t('videothumbnail.grantModerator') }));
    }
}
export default translate(connect(_mapStateToProps)(GrantModeratorButton));
