import React from 'react';
import Tooltip from '../../../base/tooltip/components/Tooltip';
import AbstractToolbarButton from '../../../toolbox/components/AbstractToolbarButton';
/**
 * Represents a button in the reactions menu.
 *
 * @augments AbstractToolbarButton
 */
class ReactionButton extends AbstractToolbarButton {
    /**
     * Initializes a new {@code ReactionButton} instance.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._onClickHandler = this._onClickHandler.bind(this);
        this.state = {
            increaseLevel: 0,
            increaseTimeout: null
        };
    }
    /**
     * Handles 'Enter' key on the button to trigger onClick for accessibility.
     * We should be handling Space onKeyUp but it conflicts with PTT.
     *
     * @param {Object} event - The key event.
     * @private
     * @returns {void}
     */
    _onKeyDown(event) {
        // If the event coming to the dialog has been subject to preventDefault
        // we don't handle it here.
        if (event.defaultPrevented) {
            return;
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.props.onClick();
        }
    }
    /**
     * Handles reaction button click.
     *
     * @param {Event} event - The click event.
     * @returns {void}
     */
    _onClickHandler(event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onClick();
        clearTimeout(this.state.increaseTimeout ?? 0);
        const timeout = window.setTimeout(() => {
            this.setState({
                increaseLevel: 0
            });
        }, 500);
        this.setState(state => {
            return {
                increaseLevel: state.increaseLevel + 1,
                increaseTimeout: timeout
            };
        });
    }
    /**
     * Renders the button of this {@code ReactionButton}.
     *
     * @param {Object} children - The children, if any, to be rendered inside
     * the button. Presumably, contains the emoji of this {@code ReactionButton}.
     * @protected
     * @returns {ReactElement} The button of this {@code ReactionButton}.
     */
    _renderButton(children) {
        return (React.createElement("div", { "aria-label": this.props.accessibilityLabel, "aria-pressed": this.props.toggled, className: 'toolbox-button', onClick: this._onClickHandler, onKeyDown: this._onKeyDown, role: 'button', tabIndex: 0 }, this.props.tooltip
            ? React.createElement(Tooltip, { content: this.props.tooltip, position: this.props.tooltipPosition }, children)
            : children));
    }
    /**
     * Renders the icon (emoji) of this {@code reactionButton}.
     *
     * @inheritdoc
     */
    _renderIcon() {
        const { toggled, icon, label } = this.props;
        const { increaseLevel } = this.state;
        return (React.createElement("div", { className: `toolbox-icon ${toggled ? 'toggled' : ''}` },
            React.createElement("span", { className: `emoji increase-${increaseLevel > 12 ? 12 : increaseLevel}` }, icon),
            label && React.createElement("span", { className: 'text' }, label)));
    }
}
/**
 * Default values for {@code ReactionButton} component's properties.
 *
 * @static
 */
ReactionButton.defaultProps = {
    tooltipPosition: 'top'
};
export default ReactionButton;
