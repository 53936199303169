import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Icon from '../../../base/icons/components/Icon';
import { IconMessage } from '../../../base/icons/svg';
import { browser } from '../../../base/lib-jitsi-meet';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
import PollItem from './PollItem';
const useStyles = makeStyles()(theme => {
    return {
        container: {
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        emptyIcon: {
            width: '100px',
            padding: '16px',
            '& svg': {
                width: '100%',
                height: 'auto'
            }
        },
        emptyMessage: {
            ...withPixelLineHeight(theme.typography.bodyLongBold),
            color: theme.palette.text02,
            padding: '0 24px',
            textAlign: 'center'
        }
    };
});
const PollsList = () => {
    const { t } = useTranslation();
    const { classes, theme } = useStyles();
    const polls = useSelector((state) => state['features/polls'].polls);
    const pollListEndRef = useRef(null);
    const scrollToBottom = useCallback(() => {
        if (pollListEndRef.current) {
            // Safari does not support options
            const param = browser.isSafari()
                ? false : {
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
            };
            pollListEndRef.current.scrollIntoView(param);
        }
    }, [pollListEndRef.current]);
    useEffect(() => {
        scrollToBottom();
    }, [polls]);
    const listPolls = Object.keys(polls);
    return (React.createElement(React.Fragment, null, listPolls.length === 0
        ? React.createElement("div", { className: classes.container },
            React.createElement(Icon, { className: classes.emptyIcon, color: theme.palette.icon03, src: IconMessage }),
            React.createElement("span", { className: classes.emptyMessage }, t('polls.results.empty')))
        : listPolls.map((id, index) => (React.createElement(PollItem, { key: id, pollId: id, ref: listPolls.length - 1 === index ? pollListEndRef : null })))));
};
export default PollsList;
