/* eslint-disable react/jsx-no-bind */
import React, { useRef, useState } from 'react';
import { translate } from '../../../../base/i18n/functions';
import { copyText } from '../../../../base/util/copyText.web';
import { LOCKED_LOCALLY } from '../../../../room-lock/constants';
import { NOTIFY_CLICK_MODE } from '../../../../toolbox/constants';
import PasswordForm from './PasswordForm';
const DIGITS_ONLY = /^\d+$/;
const KEY = 'add-passcode';
/**
 * Component that handles the password manipulation from the invite dialog.
 *
 * @returns {React$Element<any>}
 */
function PasswordSection({ buttonsWithNotifyClick, canEditPassword, conference, locked, password, passwordEditEnabled, passwordNumberOfDigits, setPassword, setPasswordEditEnabled, t }) {
    const formRef = useRef(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    /**
     * Callback invoked to set a password on the current JitsiConference.
     *
     * @param {string} enteredPassword - The new password to be used to lock the
     * current JitsiConference.
     * @private
     * @returns {void}
     */
    function onPasswordSubmit(enteredPassword) {
        if (enteredPassword && passwordNumberOfDigits && !DIGITS_ONLY.test(enteredPassword)) {
            // Don't set the password.
            return;
        }
        setPassword(conference, conference.lock, enteredPassword);
    }
    /**
     * Toggles whether or not the password should currently be shown as being
     * edited locally.
     *
     * @private
     * @returns {void}
     */
    function onTogglePasswordEditState() {
        if (typeof APP === 'undefined' || !buttonsWithNotifyClick?.length) {
            setPasswordEditEnabled(!passwordEditEnabled);
            return;
        }
        let notifyMode;
        const notify = buttonsWithNotifyClick.find((btn) => (typeof btn === 'string' && btn === KEY)
            || (typeof btn === 'object' && btn.key === KEY));
        if (notify) {
            notifyMode = typeof notify === 'string' || notify.preventExecution
                ? NOTIFY_CLICK_MODE.PREVENT_AND_NOTIFY
                : NOTIFY_CLICK_MODE.ONLY_NOTIFY;
            APP.API.notifyToolbarButtonClicked(KEY, notifyMode === NOTIFY_CLICK_MODE.PREVENT_AND_NOTIFY);
        }
        if (notifyMode === NOTIFY_CLICK_MODE.ONLY_NOTIFY) {
            setPasswordEditEnabled(!passwordEditEnabled);
        }
    }
    /**
     * Method to remotely submit the password from outside of the password form.
     *
     * @returns {void}
     */
    function onPasswordSave() {
        if (formRef.current) {
            // @ts-ignore
            const { value } = formRef.current.querySelector('div > input');
            if (value) {
                onPasswordSubmit(value);
            }
        }
    }
    /**
     * Callback invoked to unlock the current JitsiConference.
     *
     * @returns {void}
     */
    function onPasswordRemove() {
        onPasswordSubmit('');
    }
    /**
     * Copies the password to the clipboard.
     *
     * @returns {void}
     */
    function onPasswordCopy() {
        copyText(password ?? '');
    }
    /**
     * Callback invoked to show the current password.
     *
     * @returns {void}
     */
    function onPasswordShow() {
        setPasswordVisible(true);
    }
    /**
     * Callback invoked to hide the current password.
     *
     * @returns {void}
     */
    function onPasswordHide() {
        setPasswordVisible(false);
    }
    /**
     * Method that renders the password action(s) based on the current
     * locked-status of the conference.
     *
     * @returns {React$Element<any>}
     */
    function renderPasswordActions() {
        if (!canEditPassword) {
            return null;
        }
        if (passwordEditEnabled) {
            return (React.createElement(React.Fragment, null,
                React.createElement("button", { className: 'as-link', onClick: onTogglePasswordEditState, type: 'button' },
                    t('dialog.Cancel'),
                    React.createElement("span", { className: 'sr-only' },
                        "(",
                        t('dialog.password'),
                        ")")),
                React.createElement("button", { className: 'as-link', onClick: onPasswordSave, type: 'button' },
                    t('dialog.add'),
                    React.createElement("span", { className: 'sr-only' },
                        "(",
                        t('dialog.password'),
                        ")"))));
        }
        if (locked) {
            return (React.createElement(React.Fragment, null,
                React.createElement("button", { className: 'remove-password as-link', onClick: onPasswordRemove, type: 'button' },
                    t('dialog.Remove'),
                    React.createElement("span", { className: 'sr-only' },
                        "(",
                        t('dialog.password'),
                        ")")),
                // There are cases like lobby and grant moderator when password is not available
                password ? React.createElement(React.Fragment, null,
                    React.createElement("button", { className: 'copy-password as-link', onClick: onPasswordCopy, type: 'button' },
                        t('dialog.copy'),
                        React.createElement("span", { className: 'sr-only' },
                            "(",
                            t('dialog.password'),
                            ")"))) : null,
                locked === LOCKED_LOCALLY && (React.createElement("button", { className: 'as-link', onClick: passwordVisible ? onPasswordHide : onPasswordShow, type: 'button' },
                    t(passwordVisible ? 'dialog.hide' : 'dialog.show'),
                    React.createElement("span", { className: 'sr-only' },
                        "(",
                        t('dialog.password'),
                        ")")))));
        }
        return (React.createElement("button", { className: 'add-password as-link', onClick: onTogglePasswordEditState, type: 'button' }, t('info.addPassword')));
    }
    return (React.createElement("div", { className: 'security-dialog password-section' },
        React.createElement("p", { className: 'description' }, t(canEditPassword ? 'security.about' : 'security.aboutReadOnly')),
        React.createElement("div", { className: 'security-dialog password' },
            React.createElement("div", { className: 'info-dialog info-dialog-column info-dialog-password', ref: formRef },
                React.createElement(PasswordForm, { editEnabled: passwordEditEnabled, locked: locked, onSubmit: onPasswordSubmit, password: password, passwordNumberOfDigits: passwordNumberOfDigits, visible: passwordVisible })),
            React.createElement("div", { className: 'security-dialog password-actions' }, renderPasswordActions()))));
}
export default translate(PasswordSection);
