import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IconPin, IconPinned } from '../../../base/icons/svg';
import ContextMenuItem from '../../../base/ui/components/web/ContextMenuItem';
import { togglePinStageParticipant } from '../../../filmstrip/actions.web';
import { getPinnedActiveParticipants } from '../../../filmstrip/functions.web';
const TogglePinToStageButton = ({ className, noIcon = false, onClick, participantID }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isActive = Boolean(useSelector(getPinnedActiveParticipants)
        .find(p => p.participantId === participantID));
    const _onClick = useCallback(() => {
        dispatch(togglePinStageParticipant(participantID));
        onClick?.();
    }, [participantID, isActive]);
    const text = isActive
        ? t('videothumbnail.unpinFromStage')
        : t('videothumbnail.pinToStage');
    const icon = isActive ? IconPinned : IconPin;
    return (React.createElement(ContextMenuItem, { accessibilityLabel: text, icon: noIcon ? null : icon, onClick: _onClick, text: text, textClassName: className }));
};
export default TogglePinToStageButton;
