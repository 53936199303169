import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import VideoTrack from '../../../base/media/components/web/VideoTrack';
import { LAYOUTS } from '../../../video-layout/constants';
import { getCurrentLayout } from '../../../video-layout/functions.web';
import ThumbnailBottomIndicators from './ThumbnailBottomIndicators';
import ThumbnailTopIndicators from './ThumbnailTopIndicators';
const VirtualScreenshareParticipant = ({ classes, containerClassName, isHovered, isLocal, isMobile, onClick, onMouseEnter, onMouseLeave, onMouseMove, onTouchEnd, onTouchMove, onTouchStart, participantId, shouldDisplayTintBackground, styles, videoTrack, thumbnailType }) => {
    const currentLayout = useSelector(getCurrentLayout);
    const videoTrackId = videoTrack?.jitsiTrack?.getId();
    const video = videoTrack && React.createElement(VideoTrack, { id: isLocal ? 'localScreenshare_container' : `remoteVideo_${videoTrackId || ''}`, muted: true, style: styles.video, videoTrack: videoTrack });
    return (React.createElement("span", { className: containerClassName, id: `participant_${participantId}`, ...(isMobile
            ? {
                onTouchEnd,
                onTouchMove,
                onTouchStart
            }
            : {
                onClick,
                onMouseEnter,
                onMouseMove,
                onMouseLeave
            }), style: styles.thumbnail },
        video,
        React.createElement("div", { className: classes.containerBackground }),
        React.createElement("div", { className: clsx(classes.indicatorsContainer, classes.indicatorsTopContainer, currentLayout === LAYOUTS.TILE_VIEW && 'tile-view-mode') },
            React.createElement(ThumbnailTopIndicators, { isHovered: isHovered, participantId: participantId, thumbnailType: thumbnailType })),
        shouldDisplayTintBackground && React.createElement("div", { className: classes.tintBackground }),
        React.createElement("div", { className: clsx(classes.indicatorsContainer, classes.indicatorsBottomContainer, currentLayout === LAYOUTS.TILE_VIEW && 'tile-view-mode') },
            React.createElement(ThumbnailBottomIndicators, { className: classes.indicatorsBackground, local: false, participantId: participantId, showStatusIndicators: true }))));
};
export default VirtualScreenshareParticipant;
