import React from 'react';
import { connect } from 'react-redux';
const Notice = ({ _message }) => {
    if (!_message) {
        return null;
    }
    return (React.createElement("div", { className: 'notice' },
        React.createElement("span", { className: 'notice__message' }, _message)));
};
/**
 * Maps (parts of) the Redux state to the associated
 * {@code Notice}'s props.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {{
 *     _message: string,
 * }}
 */
function _mapStateToProps(state) {
    const { noticeMessage } = state['features/base/config'];
    return {
        _message: noticeMessage
    };
}
export default connect(_mapStateToProps)(Notice);
