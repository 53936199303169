import ReducerRegistry from '../base/redux/ReducerRegistry';
import { SET_NOISE_SUPPRESSION_ENABLED } from './actionTypes';
const DEFAULT_STATE = {
    enabled: true
};
/**
 * Reduces the Redux actions of the feature features/noise-suppression.
 */
ReducerRegistry.register('features/noise-suppression', (state = DEFAULT_STATE, action) => {
    const { enabled } = action;
    switch (action.type) {
        case SET_NOISE_SUPPRESSION_ENABLED:
            return {
                ...state,
                enabled
            };
        default:
            return state;
    }
});
