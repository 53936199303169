import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import { IconMessage } from '../../../base/icons/svg';
import AbstractButton from '../../../base/toolbox/components/AbstractButton';
import ChatCounter from './ChatCounter';
/**
 * Implementation of a button for accessing chat pane.
 */
class ChatButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.openChat';
        this.toggledAccessibilityLabel = 'toolbar.accessibilityLabel.closeChat';
        this.icon = IconMessage;
        this.label = 'toolbar.openChat';
        this.toggledLabel = 'toolbar.closeChat';
        this.tooltip = 'toolbar.openChat';
        this.toggledTooltip = 'toolbar.closeChat';
    }
    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props._chatOpen;
    }
    /**
     * Overrides AbstractButton's {@link Component#render()}.
     *
     * @override
     * @protected
     * @returns {boReact$Nodeolean}
     */
    render() {
        return (React.createElement("div", { className: 'toolbar-button-with-badge', key: 'chatcontainer' },
            super.render(),
            React.createElement(ChatCounter, null)));
    }
}
/**
 * Function that maps parts of Redux state tree into component props.
 *
 * @param {Object} state - Redux state.
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    return {
        _chatOpen: state['features/chat'].isOpen
    };
};
export default translate(connect(mapStateToProps)(ChatButton));
