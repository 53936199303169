import React, { Component } from 'react';
/**
 * Abstract React {@code Component} which can display speech-to-text results
 * from Jigasi as subtitles.
 */
export class AbstractCaptions extends Component {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { _requestingSubtitles, _transcripts } = this.props;
        if (!_requestingSubtitles || !_transcripts || !_transcripts.size) {
            return null;
        }
        const paragraphs = [];
        // @ts-ignore
        for (const [id, text] of _transcripts ?? []) {
            paragraphs.push(this._renderParagraph(id, text));
        }
        // @ts-ignore
        return this._renderSubtitlesContainer(paragraphs);
    }
    /**
     * Renders the transcription text.
     *
     * @abstract
     * @param {string} _id - The ID of the transcript message from which the
     * {@code text} has been created.
     * @param {string} _text - Subtitles text formatted with the participant's
     * name.
     * @protected
     * @returns {ReactElement} - The React element which displays the text.
     */
    _renderParagraph(_id, _text) {
        return React.createElement(React.Fragment, null);
    }
    /**
     * Renders the subtitles container.
     *
     * @abstract
     * @param {Array<ReactElement>} _el - An array of elements created
     * for each subtitle using the {@link _renderParagraph} method.
     * @protected
     * @returns {ReactElement} - The subtitles container.
     */
    _renderSubtitlesContainer(_el) {
        return React.createElement(React.Fragment, null);
    }
}
/**
 * Formats the transcript messages into text by prefixing participant's name to
 * avoid duplicating the effort on platform specific component.
 *
 * @param {Object} state - The redux state.
 * @private
 * @returns {Map<string, string>} - Formatted transcript subtitles mapped by
 * transcript message IDs.
 */
function _constructTranscripts(state) {
    const { _transcriptMessages } = state['features/subtitles'];
    const transcripts = new Map();
    for (const [id, transcriptMessage] of _transcriptMessages) {
        if (transcriptMessage) {
            let text = `${transcriptMessage.participantName}: `;
            if (transcriptMessage.final) {
                text += transcriptMessage.final;
            }
            else {
                const stable = transcriptMessage.stable || '';
                const unstable = transcriptMessage.unstable || '';
                text += stable + unstable;
            }
            transcripts.set(id, text);
        }
    }
    return transcripts;
}
/**
 * Maps the transcriptionSubtitles in the redux state to the associated props of
 * {@code AbstractCaptions}.
 *
 * @param {Object} state - The redux state.
 * @private
 * @returns {{
 *     _requestingSubtitles: boolean,
 *     _transcripts: Map<string, string>
 * }}
 */
export function _abstractMapStateToProps(state) {
    const { _requestingSubtitles } = state['features/subtitles'];
    const transcripts = _constructTranscripts(state);
    return {
        _requestingSubtitles,
        // avoid re-renders by setting to prop new empty Map instances.
        _transcripts: transcripts.size === 0 ? undefined : transcripts
    };
}
