import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import Dialog from '../../../base/ui/components/web/Dialog';
import { cancelWaitForOwner } from '../../actions.web';
/**
 * Authentication message dialog for host confirmation.
 *
 * @returns {React$Element<any>}
 */
class WaitForOwnerDialog extends PureComponent {
    /**
     * Instantiates a new component.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);
        this._onCancelWaitForOwner = this._onCancelWaitForOwner.bind(this);
        this._onIAmHost = this._onIAmHost.bind(this);
    }
    /**
     * Called when the cancel button is clicked.
     *
     * @private
     * @returns {void}
     */
    _onCancelWaitForOwner() {
        const { dispatch } = this.props;
        dispatch(cancelWaitForOwner());
    }
    /**
     * Called when the OK button is clicked.
     *
     * @private
     * @returns {void}
     */
    _onIAmHost() {
        const { onAuthNow } = this.props;
        onAuthNow?.();
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     */
    render() {
        const { t } = this.props;
        return (React.createElement(Dialog, { disableBackdropClose: true, hideCloseButton: true, ok: { translationKey: 'dialog.IamHost' }, onCancel: this._onCancelWaitForOwner, onSubmit: this._onIAmHost, titleKey: t('dialog.WaitingForHostTitle') },
            React.createElement("span", null, t('dialog.WaitForHostMsg'))));
    }
}
export default translate(connect()(WaitForOwnerDialog));
