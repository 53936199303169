import React from 'react';
import DialogPortal from './DialogPortal';
/**
 * Component meant to render a drawer at the bottom of the screen,
 * by creating a portal containing the component's children.
 *
 * @returns {ReactElement}
 */
function JitsiPortal({ children, className }) {
    return (React.createElement(DialogPortal, { className: `drawer-portal ${className ?? ''}` }, children));
}
export default JitsiPortal;
