import { RESET_WHITEBOARD, SETUP_WHITEBOARD, SET_WHITEBOARD_OPEN } from './actionTypes';
/**
 * Configures the whiteboard collaboration details.
 *
 * @param {Object} payload - The whiteboard settings.
 * @returns {{
 *     type: SETUP_WHITEBOARD,
 *     collabDetails: { roomId: string, roomKey: string }
 * }}
 */
export const setupWhiteboard = ({ collabDetails }) => {
    return {
        type: SETUP_WHITEBOARD,
        collabDetails
    };
};
/**
 * Cleans up the whiteboard collaboration settings.
 * To be used only on native for cleanup in between conferences.
 *
 * @returns {{
 *     type: RESET_WHITEBOARD
 * }}
 */
export const resetWhiteboard = () => {
    return { type: RESET_WHITEBOARD };
};
/**
 * Sets the whiteboard visibility status.
 *
 * @param {boolean} isOpen - The whiteboard visibility flag.
 * @returns {{
 *      type: SET_WHITEBOARD_OPEN,
 *      isOpen
 * }}
 */
export const setWhiteboardOpen = (isOpen) => {
    return {
        type: SET_WHITEBOARD_OPEN,
        isOpen
    };
};
