import { translate } from '../../base/i18n/functions';
import { IconPerformance } from '../../base/icons/svg';
import AbstractButton from '../../base/toolbox/components/AbstractButton';
/**
 * React {@code Component} responsible for displaying a button in the overflow
 * menu of the toolbar, including an icon showing the currently selected
 * max receive quality.
 *
 * @augments Component
 */
class VideoQualityButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.callQuality';
        this.label = 'videoStatus.performanceSettings';
        this.tooltip = 'videoStatus.performanceSettings';
        this.icon = IconPerformance;
    }
}
export default translate(VideoQualityButton);
