import React from 'react';
import { connect } from 'react-redux';
import { AbstractWelcomePage, _mapStateToProps } from './AbstractWelcomePage';
import getEnv from '../../../../env';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
const params = {
    oatoken: "",
    uuid: "",
    meetingId: "",
    mute: ""
};
new URL(window.location.href).searchParams.forEach((value, key) => params[key] = value);
async function fetchApi(url, options) {
    const res = await fetch(url, {
        "headers": {
            "oatoken": params.oatoken,
        },
        ...options
    });
    return res.json();
}
class WelcomePage extends AbstractWelcomePage {
    constructor(props) {
        super(props);
        this.state = {
            room: '',
            loading: true,
        };
    }
    async componentDidMount() {
        // super.componentDidMount();
        const { data: { jitsiRoomId, name } } = await fetchApi(`${getEnv().im}/conferences/${params.meetingId}`);
        const { data: { nickname } } = await fetchApi(`${getEnv().im}/user/getByUuid?uuid=${params.uuid}`);
        const avatar = `${getEnv().oa}/api/thirdApi/getUserAvatar?type=art_photo&img_size=800&uuid=${params.uuid}`;
        // APP.conference.changeLocalAvatarUrl(avatar)
        // APP.conference.changeLocalDisplayName(nickname)
        this.props._settings.avatarURL = avatar;
        this.props._settings.displayName = nickname;
        this.props._settings.startWithAudioMuted = params.mute ? !!+params.mute : true;
        this.props._settings.startWithVideoMuted = true;
        localStorage.setItem('localMeetingName', name);
        // APP.store.dispatch(setSubject(name));
        this.setState({
            loading: false,
            room: jitsiRoomId
        }, () => {
            this.submit();
        });
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(Backdrop, { open: this.state.loading, onClick: this.handleClose },
                React.createElement(CircularProgress, { color: "inherit" }))));
    }
    submit(event) {
        event?.preventDefault();
        if (this.state.room) {
            this._onJoin();
        }
    }
    handleClose() {
        this.setState({
            loading: false
        });
    }
}
export default connect(_mapStateToProps)(WelcomePage);
