import { getLocationContextRoot } from '../base/util/uri';
import { addTrackStateToURL } from './functions.any';
/**
 * Redirects to another page generated by replacing the path in the original URL
 * with the given path.
 *
 * @param {(string)} pathname - The path to navigate to.
 * @returns {Function}
 */
export function redirectWithStoredParams(pathname) {
    return (dispatch, getState) => {
        const { locationURL } = getState()['features/base/connection'];
        const newLocationURL = new URL(locationURL?.href ?? '');
        newLocationURL.pathname = pathname;
        window.location.assign(newLocationURL.toString());
    };
}
/**
 * Assigns a specific pathname to window.location.pathname taking into account
 * the context root of the Web app.
 *
 * @param {string} pathname - The pathname to assign to
 * window.location.pathname. If the specified pathname is relative, the context
 * root of the Web app will be prepended to the specified pathname before
 * assigning it to window.location.pathname.
 * @param {string} hashParam - Optional hash param to assign to
 * window.location.hash.
 * @returns {Function}
 */
export function redirectToStaticPage(pathname, hashParam) {
    return () => {
        const windowLocation = window.location;
        let newPathname = pathname;
        if (!newPathname.startsWith('/')) {
            // A pathname equal to ./ specifies the current directory. It will be
            // fine but pointless to include it because contextRoot is the current
            // directory.
            newPathname.startsWith('./')
                && (newPathname = newPathname.substring(2));
            newPathname = getLocationContextRoot(windowLocation) + newPathname;
        }
        if (hashParam) {
            windowLocation.hash = hashParam;
        }
        windowLocation.pathname = newPathname;
    };
}
/**
 * Reloads the page by restoring the original URL.
 *
 * @returns {Function}
 */
export function reloadWithStoredParams() {
    return (dispatch, getState) => {
        const state = getState();
        const { locationURL } = state['features/base/connection'];
        // Preserve the local tracks muted states.
        // @ts-ignore
        const newURL = addTrackStateToURL(locationURL, state);
        const windowLocation = window.location;
        const oldSearchString = windowLocation.search;
        windowLocation.replace(newURL.toString());
        if (newURL.search === oldSearchString) {
            // NOTE: Assuming that only the hash or search part of the URL will
            // be changed!
            // location.replace will not trigger redirect/reload when
            // only the hash params are changed. That's why we need to call
            // reload in addition to replace.
            windowLocation.reload();
        }
    };
}
