import { Component } from 'react';
import { updateSettings } from '../../base/settings/actions';
/**
 * Implements an abstract class for {@code DisplayNamePrompt}.
 */
export default class AbstractDisplayNamePrompt extends Component {
    /**
     * Instantiates a new component.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this._onSetDisplayName = this._onSetDisplayName.bind(this);
    }
    /**
     * Dispatches an action to update the local participant's display name. A
     * name must be entered for the action to dispatch.
     *
     * It returns a boolean to comply the Dialog behaviour:
     *     {@code true} - the dialog should be closed.
     *     {@code false} - the dialog should be left open.
     *
     * @param {string} displayName - The display name to save.
     * @returns {boolean}
     */
    _onSetDisplayName(displayName) {
        if (!displayName?.trim()) {
            return false;
        }
        const { dispatch, onPostSubmit } = this.props;
        // Store display name in settings
        dispatch(updateSettings({
            displayName
        }));
        onPostSubmit?.();
        return true;
    }
}
