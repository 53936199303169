import { createToolbarEvent } from '../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../analytics/functions';
import { openDialog } from '../../base/dialog/actions';
import { IconMicSlash } from '../../base/icons/svg';
import AbstractButton from '../../base/toolbox/components/AbstractButton';
import { MuteEveryoneDialog } from './';
/**
 * An abstract remote video menu button which mutes all the other participants.
 */
export default class AbstractMuteEveryoneElseButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.muteEveryoneElse';
        this.icon = IconMicSlash;
        this.label = 'videothumbnail.domuteOthers';
    }
    /**
     * Handles clicking / pressing the button, and opens a confirmation dialog.
     *
     * @private
     * @returns {void}
     */
    _handleClick() {
        const { dispatch, participantID } = this.props;
        sendAnalytics(createToolbarEvent('mute.everyoneelse.pressed'));
        dispatch(openDialog(MuteEveryoneDialog, { exclude: [participantID] }));
    }
}
