import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../base/i18n/functions';
import Label from '../../base/label/components/web/Label';
import Tooltip from '../../base/tooltip/components/Tooltip';
import { _mapStateToProps } from './AbstractTranscribingLabel';
const TranscribingLabel = ({ _showLabel, t }) => {
    if (!_showLabel) {
        return null;
    }
    return (React.createElement(Tooltip, { content: t('transcribing.labelToolTip'), position: 'left' },
        React.createElement(Label, { className: 'recording-label', text: t('transcribing.tr') })));
};
export default translate(connect(_mapStateToProps)(TranscribingLabel));
