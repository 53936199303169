import React, { useCallback, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { v4 as uuidv4 } from 'uuid';
import { translate } from '../../base/i18n/functions';
import Icon from '../../base/icons/components/Icon';
import { IconPlus } from '../../base/icons/svg';
import { withPixelLineHeight } from '../../base/styles/functions.web';
import { VIRTUAL_BACKGROUND_TYPE } from '../constants';
import { resizeImage } from '../functions';
import logger from '../logger';
const useStyles = makeStyles()(theme => {
    return {
        label: {
            ...withPixelLineHeight(theme.typography.bodyShortBold),
            color: theme.palette.link01,
            marginBottom: theme.spacing(3),
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
        },
        addBackground: {
            marginRight: theme.spacing(3),
            '& svg': {
                fill: `${theme.palette.link01} !important`
            }
        },
        input: {
            display: 'none'
        }
    };
});
/**
 * Component used to upload an image.
 *
 * @param {Object} Props - The props of the component.
 * @returns {React$Node}
 */
function UploadImageButton({ setLoading, setOptions, setStoredImages, showLabel, storedImages, t }) {
    const { classes } = useStyles();
    const uploadImageButton = useRef(null);
    const uploadImageKeyPress = useCallback(e => {
        if (uploadImageButton.current && (e.key === ' ' || e.key === 'Enter')) {
            e.preventDefault();
            uploadImageButton.current.click();
        }
    }, [uploadImageButton.current]);
    const uploadImage = useCallback(async (e) => {
        const reader = new FileReader();
        const imageFile = e.target.files;
        reader.readAsDataURL(imageFile[0]);
        reader.onload = async () => {
            const url = await resizeImage(reader.result);
            const uuId = uuidv4();
            setStoredImages([
                ...storedImages,
                {
                    id: uuId,
                    src: url
                }
            ]);
            setOptions({
                backgroundType: VIRTUAL_BACKGROUND_TYPE.IMAGE,
                enabled: true,
                url,
                selectedThumbnail: uuId
            });
        };
        logger.info('New virtual background image uploaded!');
        reader.onerror = () => {
            setLoading(false);
            logger.error('Failed to upload virtual image!');
        };
    }, [storedImages]);
    return (React.createElement(React.Fragment, null,
        showLabel && React.createElement("label", { className: classes.label, htmlFor: 'file-upload', onKeyPress: uploadImageKeyPress, tabIndex: 0 },
            React.createElement(Icon, { className: classes.addBackground, size: 24, src: IconPlus }),
            t('virtualBackground.addBackground')),
        React.createElement("input", { accept: 'image/*', className: classes.input, id: 'file-upload', onChange: uploadImage, ref: uploadImageButton, type: 'file' })));
}
export default translate(UploadImageButton);
