import { Component } from 'react';
import { JitsiRecordingConstants } from '../../base/lib-jitsi-meet';
import { getSessionStatusToShow } from '../functions';
/**
 * The timeout after a label is considered stale. See {@code _updateStaleStatus}
 * for more details.
 */
const STALE_TIMEOUT = 10 * 1000;
/**
 * Abstract class for the {@code RecordingLabel} component.
 */
export default class AbstractRecordingLabel extends Component {
    /**
     * Implements {@code Component#getDerivedStateFromProps}.
     *
     * @inheritdoc
     */
    static getDerivedStateFromProps(props, prevState) {
        return {
            staleLabel: props._status !== JitsiRecordingConstants.status.OFF
                && prevState.staleLabel ? false : prevState.staleLabel
        };
    }
    /**
     * Initializes a new {@code AbstractRecordingLabel} component.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this.state = {
            staleLabel: true
        };
        this._updateStaleStatus(undefined, props);
    }
    /**
     * Implements React {@code Component}'s componentDidMount.
     *
     * @inheritdoc
     */
    componentDidMount() {
        this._mounted = true;
    }
    /**
     * Implements React {@code Component}'s componentWillUnmount.
     *
     * @inheritdoc
     */
    componentWillUnmount() {
        this._mounted = false;
    }
    /**
     * Implements {@code Component#componentDidUpdate}.
     *
     * @inheritdoc
     */
    componentDidUpdate(prevProps) {
        this._updateStaleStatus(prevProps, this.props);
    }
    /**
     * Implements React {@code Component}'s render.
     *
     * @inheritdoc
     */
    render() {
        return this.props._status && !this.state.staleLabel && !this.props._iAmRecorder
            ? this._renderLabel() : null;
    }
    /**
     * Returns the label key that this indicator should render.
     *
     * @protected
     * @returns {?string}
     */
    _getLabelKey() {
        switch (this.props.mode) {
            case JitsiRecordingConstants.mode.STREAM:
                return 'recording.live';
            case JitsiRecordingConstants.mode.FILE:
                return 'recording.rec';
            default:
                // Invalid mode is passed to the component.
                return undefined;
        }
    }
    /**
     * Renders the platform specific label component.
     *
     * @protected
     * @returns {React$Element}
     */
    _renderLabel() {
        return null;
    }
    /**
     * Updates the stale status of the label on a prop change. A label is stale
     * if it's in a {@code _status} that doesn't need to be rendered anymore.
     *
     * @param {IProps} oldProps - The previous props of the component.
     * @param {IProps} newProps - The new props of the component.
     * @returns {void}
     */
    _updateStaleStatus(oldProps, newProps) {
        if (newProps._status === JitsiRecordingConstants.status.OFF) {
            if (oldProps?._status !== JitsiRecordingConstants.status.OFF) {
                setTimeout(() => {
                    if (!this._mounted) {
                        return;
                    }
                    // Only if it's still OFF.
                    if (this.props._status
                        === JitsiRecordingConstants.status.OFF) {
                        this.setState({
                            staleLabel: true
                        });
                    }
                }, STALE_TIMEOUT);
            }
        }
    }
}
/**
 * Maps (parts of) the Redux state to the associated
 * {@code AbstractRecordingLabel}'s props.
 *
 * @param {Object} state - The Redux state.
 * @param {IProps} ownProps - The component's own props.
 * @private
 * @returns {{
 *     _status: ?string
 * }}
 */
export function _mapStateToProps(state, ownProps) {
    const { mode } = ownProps;
    return {
        _iAmRecorder: Boolean(state['features/base/config'].iAmRecorder),
        _status: getSessionStatusToShow(state, mode)
    };
}
