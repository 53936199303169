const dev = {
    im: '//imdev.imgo.tv',
    oa: '//oadev.imgo.tv'
};
const prod = {
    im: '//oaim.imgo.tv',
    oa: '//oa.imgo.tv'
};
export default function () {
    if (location.host !== 'meeting.imgo.tv') {
        return dev;
    }
    return prod;
}
