import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import { IconUsers } from '../../../base/icons/svg';
import AbstractButton from '../../../base/toolbox/components/AbstractButton';
import ParticipantsCounter from './ParticipantsCounter';
/**
 * Implementation of a button for accessing participants pane.
 */
class ParticipantsPaneButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.participants';
        this.toggledAccessibilityLabel = 'toolbar.accessibilityLabel.closeParticipantsPane';
        this.icon = IconUsers;
        this.label = 'toolbar.participants';
        this.tooltip = 'toolbar.participants';
        this.toggledTooltip = 'toolbar.closeParticipantsPane';
    }
    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props._isOpen;
    }
    /**
     * Overrides AbstractButton's {@link Component#render()}.
     *
     * @override
     * @protected
     * @returns {React$Node}
     */
    render() {
        return (React.createElement("div", { className: 'toolbar-button-with-badge' },
            super.render(),
            React.createElement(ParticipantsCounter, null)));
    }
}
/**
 * Maps part of the Redux state to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @returns {IProps}
 */
function mapStateToProps(state) {
    const { isOpen } = state['features/participants-pane'];
    return {
        _isOpen: isOpen
    };
}
export default translate(connect(mapStateToProps)(ParticipantsPaneButton));
