/**
 * Maps (parts of) the redux state to the associated props of the
 * {@link AbstractTranscribingLabel} {@code Component}.
 *
 * @param {Object} state - The redux state.
 * @private
 * @returns {{
 *     _showLabel: boolean
 * }}
 */
export function _mapStateToProps(state) {
    return {
        _showLabel: state['features/transcribing'].isTranscribing
    };
}
