import { Component } from 'react';
import { getLocalParticipant } from '../../base/participants/functions';
import { sendMessage, setIsPollsTabFocused } from '../actions';
import { SMALL_WIDTH_THRESHOLD } from '../constants';
/**
 * Implements an abstract chat panel.
 */
export default class AbstractChat extends Component {
    /**
     * Initializes a new {@code AbstractChat} instance.
     *
     * @param {Props} props - The React {@code Component} props to initialize
     * the new {@code AbstractChat} instance with.
     */
    constructor(props) {
        super(props);
        // Bind event handlers so they are only bound once per instance.
        this._onSendMessage = this._onSendMessage.bind(this);
        this._onToggleChatTab = this._onToggleChatTab.bind(this);
        this._onTogglePollsTab = this._onTogglePollsTab.bind(this);
    }
    /**
    * Sends a text message.
    *
    * @private
    * @param {string} text - The text message to be sent.
    * @returns {void}
    * @type {Function}
    */
    _onSendMessage(text) {
        this.props.dispatch(sendMessage(text));
    }
    /**
     * Display the Chat tab.
     *
     * @private
     * @returns {void}
     */
    _onToggleChatTab() {
        this.props.dispatch(setIsPollsTabFocused(false));
    }
    /**
     * Display the Polls tab.
     *
     * @private
     * @returns {void}
     */
    _onTogglePollsTab() {
        this.props.dispatch(setIsPollsTabFocused(true));
    }
}
/**
 * Maps (parts of) the redux state to {@link Chat} React {@code Component}
 * props.
 *
 * @param {Object} state - The redux store/state.
 * @param {any} _ownProps - Components' own props.
 * @private
 * @returns {{
 *     _isOpen: boolean,
 *     _messages: Array<Object>,
 *     _showNamePrompt: boolean
 * }}
 */
export function _mapStateToProps(state, _ownProps) {
    const { isOpen, isPollsTabFocused, messages, nbUnreadMessages } = state['features/chat'];
    const { nbUnreadPolls } = state['features/polls'];
    const _localParticipant = getLocalParticipant(state);
    const { disablePolls } = state['features/base/config'];
    return {
        _isModal: window.innerWidth <= SMALL_WIDTH_THRESHOLD,
        _isOpen: isOpen,
        _isPollsEnabled: !disablePolls,
        _isPollsTabFocused: isPollsTabFocused,
        _messages: messages,
        _nbUnreadMessages: nbUnreadMessages,
        _nbUnreadPolls: nbUnreadPolls,
        _showNamePrompt: !_localParticipant?.name
    };
}
