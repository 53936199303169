import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { batch, connect } from 'react-redux';
import { isSpeakerStatsDisabled } from '../../../../features/speaker-stats/functions';
import { ACTION_SHORTCUT_TRIGGERED, createShortcutEvent, createToolbarEvent } from '../../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../../analytics/functions';
import { VISITORS_MODE_BUTTONS } from '../../../base/config/constants';
import { getButtonsWithNotifyClick, getMultipleVideoSendingSupportFeatureFlag, getToolbarButtons, isToolbarButtonEnabled } from '../../../base/config/functions.web';
import { openDialog, toggleDialog } from '../../../base/dialog/actions';
import { isIosMobileBrowser, isMobileBrowser } from '../../../base/environment/utils';
import { translate } from '../../../base/i18n/functions';
import JitsiMeetJS from '../../../base/lib-jitsi-meet';
import { raiseHand } from '../../../base/participants/actions';
import { getLocalParticipant, hasRaisedHand, isLocalParticipantModerator } from '../../../base/participants/functions';
import { getLocalVideoTrack } from '../../../base/tracks/functions.web';
import ContextMenu from '../../../base/ui/components/web/ContextMenu';
import { toggleChat } from '../../../chat/actions.web';
import ChatButton from '../../../chat/components/web/ChatButton';
import EmbedMeetingButton from '../../../embed-meeting/components/EmbedMeetingButton';
import SharedDocumentButton from '../../../etherpad/components/SharedDocumentButton.web';
import FeedbackButton from '../../../feedback/components/FeedbackButton.web';
import { setGifMenuVisibility } from '../../../gifs/actions';
import { isGifEnabled } from '../../../gifs/functions.web';
import InviteButton from '../../../invite/components/add-people-dialog/web/InviteButton';
import { isVpaasMeeting } from '../../../jaas/functions';
import { registerShortcut, unregisterShortcut } from '../../../keyboard-shortcuts/actions';
import KeyboardShortcutsButton from '../../../keyboard-shortcuts/components/web/KeyboardShortcutsButton';
import { areKeyboardShortcutsEnabled } from '../../../keyboard-shortcuts/functions';
import NoiseSuppressionButton from '../../../noise-suppression/components/NoiseSuppressionButton';
import { close as closeParticipantsPane, open as openParticipantsPane } from '../../../participants-pane/actions.web';
import ParticipantsPaneButton from '../../../participants-pane/components/web/ParticipantsPaneButton';
import { getParticipantsPaneOpen } from '../../../participants-pane/functions';
import { addReactionToBuffer, toggleReactionsMenuVisibility } from '../../../reactions/actions.web';
import RaiseHandButton from '../../../reactions/components/web/RaiseHandButton';
import ReactionsMenuButton from '../../../reactions/components/web/ReactionsMenuButton';
import { REACTIONS } from '../../../reactions/constants';
import { isReactionsButtonEnabled, isReactionsEnabled } from '../../../reactions/functions.web';
import LiveStreamButton from '../../../recording/components/LiveStream/web/LiveStreamButton';
import RecordButton from '../../../recording/components/Recording/web/RecordButton';
import { isSalesforceEnabled } from '../../../salesforce/functions';
import { startScreenShareFlow } from '../../../screen-share/actions.web';
import ShareAudioButton from '../../../screen-share/components/web/ShareAudioButton';
import { isScreenAudioSupported, isScreenVideoShared } from '../../../screen-share/functions';
import SecurityDialogButton from '../../../security/components/security-dialog/web/SecurityDialogButton';
import SettingsButton from '../../../settings/components/web/SettingsButton';
import SharedVideoButton from '../../../shared-video/components/web/SharedVideoButton';
import SpeakerStats from '../../../speaker-stats/components/web/SpeakerStats';
import SpeakerStatsButton from '../../../speaker-stats/components/web/SpeakerStatsButton';
import ClosedCaptionButton from '../../../subtitles/components/web/ClosedCaptionButton';
import { toggleTileView } from '../../../video-layout/actions.web';
import TileViewButton from '../../../video-layout/components/TileViewButton';
import { shouldDisplayTileView } from '../../../video-layout/functions.web';
import VideoQualityButton from '../../../video-quality/components/VideoQualityButton.web';
import VideoQualityDialog from '../../../video-quality/components/VideoQualityDialog.web';
import VideoBackgroundButton from '../../../virtual-background/components/VideoBackgroundButton';
import { iAmVisitor } from '../../../visitors/functions';
import WhiteboardButton from '../../../whiteboard/components/web/WhiteboardButton';
import { isWhiteboardButtonVisible } from '../../../whiteboard/functions';
import { setFullScreen, setHangupMenuVisible, setOverflowMenuVisible, setToolbarHovered, showToolbox } from '../../actions.web';
import { NOTIFY_CLICK_MODE, NOT_APPLICABLE, THRESHOLDS } from '../../constants';
import { getJwtDisabledButtons, isDesktopShareButtonDisabled, isToolboxVisible } from '../../functions.web';
import DownloadButton from '../DownloadButton';
import HangupButton from '../HangupButton';
import HelpButton from '../HelpButton';
import AudioSettingsButton from './AudioSettingsButton';
import CustomOptionButton from './CustomOptionButton';
import { EndConferenceButton } from './EndConferenceButton';
import FullscreenButton from './FullscreenButton';
import HangupMenuButton from './HangupMenuButton';
import { LeaveConferenceButton } from './LeaveConferenceButton';
import LinkToSalesforceButton from './LinkToSalesforceButton';
import OverflowMenuButton from './OverflowMenuButton';
import ProfileButton from './ProfileButton';
import Separator from './Separator';
import ShareDesktopButton from './ShareDesktopButton';
import ToggleCameraButton from './ToggleCameraButton';
import VideoSettingsButton from './VideoSettingsButton';
const styles = () => {
    return {
        contextMenu: {
            position: 'relative',
            right: 'auto',
            margin: 0,
            marginBottom: '8px',
            maxHeight: 'calc(100vh - 100px)',
            minWidth: '240px'
        },
        hangupMenu: {
            position: 'relative',
            right: 'auto',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
            margin: 0,
            padding: '16px',
            marginBottom: '4px'
        }
    };
};
/**
 * Implements the conference toolbox on React/Web.
 *
 * @augments Component
 */
class Toolbox extends Component {
    /**
     * Initializes a new {@code Toolbox} instance.
     *
     * @param {IProps} props - The read-only React {@code Component} props with
     * which the new instance is to be initialized.
     */
    constructor(props) {
        super(props);
        this._toolboxRef = React.createRef();
        // Bind event handlers so they are only bound once per instance.
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onSetHangupVisible = this._onSetHangupVisible.bind(this);
        this._onSetOverflowVisible = this._onSetOverflowVisible.bind(this);
        this._onTabIn = this._onTabIn.bind(this);
        this._onShortcutToggleChat = this._onShortcutToggleChat.bind(this);
        this._onShortcutToggleFullScreen = this._onShortcutToggleFullScreen.bind(this);
        this._onShortcutToggleParticipantsPane = this._onShortcutToggleParticipantsPane.bind(this);
        this._onShortcutToggleRaiseHand = this._onShortcutToggleRaiseHand.bind(this);
        this._onShortcutToggleScreenshare = this._onShortcutToggleScreenshare.bind(this);
        this._onShortcutToggleVideoQuality = this._onShortcutToggleVideoQuality.bind(this);
        this._onToolbarToggleParticipantsPane = this._onToolbarToggleParticipantsPane.bind(this);
        this._onToolbarOpenVideoQuality = this._onToolbarOpenVideoQuality.bind(this);
        this._onToolbarToggleChat = this._onToolbarToggleChat.bind(this);
        this._onToolbarToggleFullScreen = this._onToolbarToggleFullScreen.bind(this);
        this._onToolbarToggleRaiseHand = this._onToolbarToggleRaiseHand.bind(this);
        this._onToolbarToggleScreenshare = this._onToolbarToggleScreenshare.bind(this);
        this._onShortcutToggleTileView = this._onShortcutToggleTileView.bind(this);
        this._onShortcutSpeakerStats = this._onShortcutSpeakerStats.bind(this);
        this._onEscKey = this._onEscKey.bind(this);
    }
    /**
     * Sets keyboard shortcuts for to trigger ToolbarButtons actions.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentDidMount() {
        const { _toolbarButtons, t, dispatch, _reactionsEnabled, _gifsEnabled, _isSpeakerStatsDisabled } = this.props;
        const KEYBOARD_SHORTCUTS = [
            isToolbarButtonEnabled('videoquality', _toolbarButtons) && {
                character: 'A',
                exec: this._onShortcutToggleVideoQuality,
                helpDescription: 'toolbar.callQuality'
            },
            isToolbarButtonEnabled('chat', _toolbarButtons) && {
                character: 'C',
                exec: this._onShortcutToggleChat,
                helpDescription: 'keyboardShortcuts.toggleChat'
            },
            isToolbarButtonEnabled('desktop', _toolbarButtons) && {
                character: 'D',
                exec: this._onShortcutToggleScreenshare,
                helpDescription: 'keyboardShortcuts.toggleScreensharing'
            },
            isToolbarButtonEnabled('participants-pane', _toolbarButtons) && {
                character: 'P',
                exec: this._onShortcutToggleParticipantsPane,
                helpDescription: 'keyboardShortcuts.toggleParticipantsPane'
            },
            isToolbarButtonEnabled('raisehand', _toolbarButtons) && {
                character: 'R',
                exec: this._onShortcutToggleRaiseHand,
                helpDescription: 'keyboardShortcuts.raiseHand'
            },
            isToolbarButtonEnabled('fullscreen', _toolbarButtons) && {
                character: 'S',
                exec: this._onShortcutToggleFullScreen,
                helpDescription: 'keyboardShortcuts.fullScreen'
            },
            isToolbarButtonEnabled('tileview', _toolbarButtons) && {
                character: 'W',
                exec: this._onShortcutToggleTileView,
                helpDescription: 'toolbar.tileViewToggle'
            },
            !_isSpeakerStatsDisabled && isToolbarButtonEnabled('stats', _toolbarButtons) && {
                character: 'T',
                exec: this._onShortcutSpeakerStats,
                helpDescription: 'keyboardShortcuts.showSpeakerStats'
            }
        ];
        KEYBOARD_SHORTCUTS.forEach(shortcut => {
            if (typeof shortcut === 'object') {
                dispatch(registerShortcut({
                    character: shortcut.character,
                    handler: shortcut.exec,
                    helpDescription: shortcut.helpDescription
                }));
            }
        });
        if (_reactionsEnabled) {
            const REACTION_SHORTCUTS = Object.keys(REACTIONS).map(key => {
                const onShortcutSendReaction = () => {
                    dispatch(addReactionToBuffer(key));
                    sendAnalytics(createShortcutEvent(`reaction.${key}`));
                };
                return {
                    character: REACTIONS[key].shortcutChar,
                    exec: onShortcutSendReaction,
                    helpDescription: t(`toolbar.reaction${key.charAt(0).toUpperCase()}${key.slice(1)}`),
                    altKey: true
                };
            });
            REACTION_SHORTCUTS.forEach(shortcut => {
                dispatch(registerShortcut({
                    alt: shortcut.altKey,
                    character: shortcut.character,
                    handler: shortcut.exec,
                    helpDescription: shortcut.helpDescription
                }));
            });
            if (_gifsEnabled) {
                const onGifShortcut = () => {
                    batch(() => {
                        dispatch(toggleReactionsMenuVisibility());
                        dispatch(setGifMenuVisibility(true));
                    });
                };
                dispatch(registerShortcut({
                    character: 'G',
                    handler: onGifShortcut,
                    helpDescription: 'keyboardShortcuts.giphyMenu'
                }));
            }
        }
    }
    /**
     * Update the visibility of the {@code OverflowMenuButton}.
     *
     * @inheritdoc
     */
    componentDidUpdate(prevProps) {
        const { _dialog, _visible, dispatch } = this.props;
        if (prevProps._overflowMenuVisible
            && !prevProps._dialog
            && _dialog) {
            this._onSetOverflowVisible(false);
            dispatch(setToolbarHovered(false));
        }
        if (prevProps._hangupMenuVisible
            && prevProps._visible
            && !_visible) {
            this._onSetHangupVisible(false);
            dispatch(setToolbarHovered(false));
        }
        if (!_visible && prevProps._visible !== _visible) {
            if (document.activeElement instanceof HTMLElement
                && this._toolboxRef.current?.contains(document.activeElement)) {
                document.activeElement.blur();
            }
        }
    }
    /**
     * Removes keyboard shortcuts registered by this component.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentWillUnmount() {
        const { dispatch } = this.props;
        ['A', 'C', 'D', 'R', 'S'].forEach(letter => dispatch(unregisterShortcut(letter)));
        if (this.props._reactionsEnabled) {
            Object.keys(REACTIONS).map(key => REACTIONS[key].shortcutChar)
                .forEach(letter => dispatch(unregisterShortcut(letter, true)));
        }
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        if (this.props._disabled) {
            return null;
        }
        const { _chatOpen, _visible, _toolbarButtons } = this.props;
        const rootClassNames = `new-toolbox ${_visible ? 'visible' : ''} ${_toolbarButtons.length ? '' : 'no-buttons'} ${_chatOpen ? 'shift-right' : ''}`;
        return (React.createElement("div", { className: clsx(rootClassNames, this.props._shiftUp && 'shift-up'), id: 'new-toolbox' }, this._renderToolboxContent()));
    }
    /**
     * Key handler for overflow/hangup menus.
     *
     * @param {KeyboardEvent} e - Esc key click to close the popup.
     * @returns {void}
     */
    _onEscKey(e) {
        if (e?.key === 'Escape') {
            e?.stopPropagation();
            this._closeHangupMenuIfOpen();
            this._closeOverflowMenuIfOpen();
        }
    }
    /**
     * Closes the hangup menu if opened.
     *
     * @private
     * @returns {void}
     */
    _closeHangupMenuIfOpen() {
        const { dispatch, _hangupMenuVisible } = this.props;
        _hangupMenuVisible && dispatch(setHangupMenuVisible(false));
    }
    /**
     * Closes the overflow menu if opened.
     *
     * @private
     * @returns {void}
     */
    _closeOverflowMenuIfOpen() {
        const { dispatch, _overflowMenuVisible } = this.props;
        _overflowMenuVisible && dispatch(setOverflowMenuVisible(false));
    }
    /**
     * Dispatches an action to open the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _doOpenVideoQuality() {
        this.props.dispatch(openDialog(VideoQualityDialog));
    }
    /**
     * Dispatches an action to toggle the display of chat.
     *
     * @private
     * @returns {void}
     */
    _doToggleChat() {
        this.props.dispatch(toggleChat());
    }
    /**
     * Dispatches an action to toggle screensharing.
     *
     * @private
     * @returns {void}
     */
    _doToggleFullScreen() {
        const fullScreen = !this.props._fullScreen;
        this.props.dispatch(setFullScreen(fullScreen));
    }
    /**
     * Dispatches an action to toggle the local participant's raised hand state.
     *
     * @private
     * @returns {void}
     */
    _doToggleRaiseHand() {
        const { _raisedHand } = this.props;
        this.props.dispatch(raiseHand(!_raisedHand));
    }
    /**
     * Dispatches an action to toggle screensharing.
     *
     * @private
     * @param {boolean} enabled - The state to toggle screen sharing to.
     * @param {boolean} audioOnly - Only share system audio.
     * @returns {void}
     */
    _doToggleScreenshare() {
        const { _desktopSharingButtonDisabled, _desktopSharingEnabled, _screenSharing, dispatch } = this.props;
        if (_desktopSharingEnabled && !_desktopSharingButtonDisabled) {
            dispatch(startScreenShareFlow(!_screenSharing));
        }
    }
    /**
     * Dispatches an action to toggle the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _doToggleVideoQuality() {
        this.props.dispatch(toggleDialog(VideoQualityDialog));
    }
    /**
     * Dispaches an action to toggle tile view.
     *
     * @private
     * @returns {void}
     */
    _doToggleTileView() {
        this.props.dispatch(toggleTileView());
    }
    /**
     * Returns all buttons that could be rendered.
     *
     * @param {Object} state - The redux state.
     * @returns {Object} The button maps mainMenuButtons and overflowMenuButtons.
     */
    _getAllButtons() {
        const { _customToolbarButtons, _feedbackConfigured, _hasSalesforce, _isIosMobile, _isMobile, _isNarrowLayout, _isSpeakerStatsDisabled, _multiStreamModeEnabled, _reactionsButtonEnabled, _reactionsEnabled, _screenSharing, _shortcutsEnabled, _whiteboardEnabled } = this.props;
        const microphone = {
            key: 'microphone',
            Content: AudioSettingsButton,
            group: 0
        };
        const camera = {
            key: 'camera',
            Content: VideoSettingsButton,
            group: 0
        };
        const profile = this._isProfileVisible() && {
            key: 'profile',
            Content: ProfileButton,
            group: 1
        };
        const chat = {
            key: 'chat',
            Content: ChatButton,
            handleClick: this._onToolbarToggleChat,
            group: 2
        };
        const desktop = this._showDesktopSharingButton() && {
            key: 'desktop',
            Content: ShareDesktopButton,
            handleClick: this._onToolbarToggleScreenshare,
            group: 2
        };
        // In Narrow layout and mobile web we are using drawer for popups and that is why it is better to include
        // all forms of reactions in the overflow menu. Otherwise the toolbox will be hidden and the reactions popup
        // misaligned.
        const showReactionsAsPartOfRaiseHand = !_reactionsButtonEnabled && _reactionsEnabled && !_isNarrowLayout && !_isMobile;
        const raisehand = {
            key: 'raisehand',
            Content: showReactionsAsPartOfRaiseHand ? ReactionsMenuButton : RaiseHandButton,
            handleClick: this._onToolbarToggleRaiseHand,
            group: 2
        };
        const reactions = _reactionsButtonEnabled && _reactionsEnabled && {
            key: 'reactions',
            Content: ReactionsMenuButton,
            handleClick: this._onToolbarToggleRaiseHand,
            group: 2
        };
        const participants = {
            key: 'participants-pane',
            Content: ParticipantsPaneButton,
            handleClick: this._onToolbarToggleParticipantsPane,
            group: 2
        };
        const invite = {
            key: 'invite',
            Content: InviteButton,
            group: 2
        };
        const tileview = {
            key: 'tileview',
            Content: TileViewButton,
            group: 2
        };
        const toggleCamera = {
            key: 'toggle-camera',
            Content: ToggleCameraButton,
            group: 2
        };
        const videoQuality = {
            key: 'videoquality',
            Content: VideoQualityButton,
            handleClick: this._onToolbarOpenVideoQuality,
            group: 2
        };
        const fullscreen = !_isIosMobile && {
            key: 'fullscreen',
            Content: FullscreenButton,
            handleClick: this._onToolbarToggleFullScreen,
            group: 2
        };
        const security = {
            key: 'security',
            alias: 'info',
            Content: SecurityDialogButton,
            group: 2
        };
        const cc = {
            key: 'closedcaptions',
            Content: ClosedCaptionButton,
            group: 2
        };
        const recording = {
            key: 'recording',
            Content: RecordButton,
            group: 2
        };
        const livestreaming = {
            key: 'livestreaming',
            Content: LiveStreamButton,
            group: 2
        };
        const linkToSalesforce = _hasSalesforce && {
            key: 'linktosalesforce',
            Content: LinkToSalesforceButton,
            group: 2
        };
        const shareVideo = {
            key: 'sharedvideo',
            Content: SharedVideoButton,
            group: 3
        };
        const shareAudio = this._showAudioSharingButton() && {
            key: 'shareaudio',
            Content: ShareAudioButton,
            group: 3
        };
        const noiseSuppression = {
            key: 'noisesuppression',
            Content: NoiseSuppressionButton,
            group: 3
        };
        const whiteboard = _whiteboardEnabled && {
            key: 'whiteboard',
            Content: WhiteboardButton,
            group: 3
        };
        const etherpad = {
            key: 'etherpad',
            Content: SharedDocumentButton,
            group: 3
        };
        const virtualBackground = (_multiStreamModeEnabled || !_screenSharing) && {
            key: 'select-background',
            Content: VideoBackgroundButton,
            group: 3
        };
        const speakerStats = !_isSpeakerStatsDisabled && {
            key: 'stats',
            Content: SpeakerStatsButton,
            group: 3
        };
        const settings = {
            key: 'settings',
            Content: SettingsButton,
            group: 4
        };
        const shortcuts = !_isMobile && _shortcutsEnabled && {
            key: 'shortcuts',
            Content: KeyboardShortcutsButton,
            group: 4
        };
        const embed = this._isEmbedMeetingVisible() && {
            key: 'embedmeeting',
            Content: EmbedMeetingButton,
            group: 4
        };
        const feedback = _feedbackConfigured && {
            key: 'feedback',
            Content: FeedbackButton,
            group: 4
        };
        const download = {
            key: 'download',
            Content: DownloadButton,
            group: 4
        };
        const help = {
            key: 'help',
            Content: HelpButton,
            group: 4
        };
        const customButtons = _customToolbarButtons?.reduce((prev, { icon, id, text }) => {
            return {
                ...prev,
                [id]: {
                    key: id,
                    Content: CustomOptionButton,
                    group: 4,
                    icon,
                    text
                }
            };
        }, {});
        return {
            microphone,
            camera,
            profile,
            desktop,
            chat,
            raisehand,
            reactions,
            participants,
            invite,
            tileview,
            toggleCamera,
            videoQuality,
            fullscreen,
            security,
            cc,
            recording,
            livestreaming,
            linkToSalesforce,
            shareVideo,
            shareAudio,
            noiseSuppression,
            whiteboard,
            etherpad,
            virtualBackground,
            speakerStats,
            settings,
            shortcuts,
            embed,
            feedback,
            download,
            help,
            ...customButtons
        };
    }
    /**
     * Returns the notify mode of the given toolbox button.
     *
     * @param {string} btnName - The toolbar button's name.
     * @returns {string|undefined} - The button's notify mode.
     */
    _getButtonNotifyMode(btnName) {
        const notify = this.props._buttonsWithNotifyClick?.find(btn => (typeof btn === 'string' && btn === btnName)
            || (typeof btn === 'object' && btn.key === btnName));
        if (notify) {
            return typeof notify === 'string' || notify.preventExecution
                ? NOTIFY_CLICK_MODE.PREVENT_AND_NOTIFY
                : NOTIFY_CLICK_MODE.ONLY_NOTIFY;
        }
    }
    /**
     * Sets the notify click mode for the buttons.
     *
     * @param {Object} buttons - The list of toolbar buttons.
     * @returns {void}
     */
    _setButtonsNotifyClickMode(buttons) {
        if (typeof APP === 'undefined' || !this.props._buttonsWithNotifyClick?.length) {
            return;
        }
        Object.values(buttons).forEach((button) => {
            if (typeof button === 'object') {
                button.notifyMode = this._getButtonNotifyMode(button.key);
            }
        });
    }
    /**
     * Returns all buttons that need to be rendered.
     *
     * @param {Object} state - The redux state.
     * @returns {Object} The visible buttons arrays .
     */
    _getVisibleButtons() {
        const { _clientWidth, _toolbarButtons, _jwtDisabledButons } = this.props;
        const buttons = this._getAllButtons();
        this._setButtonsNotifyClickMode(buttons);
        const isHangupVisible = isToolbarButtonEnabled('hangup', _toolbarButtons);
        let { order } = THRESHOLDS.find(({ width }) => _clientWidth > width)
            || THRESHOLDS[THRESHOLDS.length - 1];
        const keys = Object.keys(buttons);
        const filtered = [
            ...order.map(key => buttons[key]),
            ...Object.values(buttons).filter((button, index) => !order.includes(keys[index]))
        ].filter(Boolean).filter(({ key, alias = NOT_APPLICABLE }) => !_jwtDisabledButons.includes(key)
            && (isToolbarButtonEnabled(key, _toolbarButtons) || isToolbarButtonEnabled(alias, _toolbarButtons)));
        const filteredKeys = filtered.map(button => button.key);
        order = order.filter(key => filteredKeys.includes(buttons[key].key));
        let sliceIndex = order.length + 2;
        if (isHangupVisible) {
            sliceIndex -= 1;
        }
        // This implies that the overflow button will be displayed, so save some space for it.
        if (sliceIndex < filtered.length) {
            sliceIndex -= 1;
        }
        return {
            mainMenuButtons: filtered.slice(0, sliceIndex),
            overflowMenuButtons: filtered.slice(sliceIndex)
        };
    }
    /**
     * Dispatches an action signaling the toolbar is not being hovered.
     *
     * @private
     * @returns {void}
     */
    _onMouseOut() {
        const { _overflowMenuVisible, dispatch } = this.props;
        !_overflowMenuVisible && dispatch(setToolbarHovered(false));
    }
    /**
     * Dispatches an action signaling the toolbar is being hovered.
     *
     * @private
     * @returns {void}
     */
    _onMouseOver() {
        this.props.dispatch(setToolbarHovered(true));
    }
    /**
     * Sets the visibility of the hangup menu.
     *
     * @param {boolean} visible - Whether or not the hangup menu should be
     * displayed.
     * @private
     * @returns {void}
     */
    _onSetHangupVisible(visible) {
        this.props.dispatch(setHangupMenuVisible(visible));
        this.props.dispatch(setToolbarHovered(visible));
    }
    /**
     * Sets the visibility of the overflow menu.
     *
     * @param {boolean} visible - Whether or not the overflow menu should be
     * displayed.
     * @private
     * @returns {void}
     */
    _onSetOverflowVisible(visible) {
        this.props.dispatch(setOverflowMenuVisible(visible));
        this.props.dispatch(setToolbarHovered(visible));
    }
    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling the display of chat.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleChat() {
        sendAnalytics(createShortcutEvent('toggle.chat', ACTION_SHORTCUT_TRIGGERED, {
            enable: !this.props._chatOpen
        }));
        // Checks if there was any text selected by the user.
        // Used for when we press simultaneously keys for copying
        // text messages from the chat board
        if (window.getSelection()?.toString() !== '') {
            return false;
        }
        this._doToggleChat();
    }
    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling the display of the participants pane.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleParticipantsPane() {
        sendAnalytics(createShortcutEvent('toggle.participants-pane', ACTION_SHORTCUT_TRIGGERED, {
            enable: !this.props._participantsPaneOpen
        }));
        this._onToolbarToggleParticipantsPane();
    }
    /**
    * Creates an analytics keyboard shortcut event and dispatches an action for
    * toggling the display of Video Quality.
    *
    * @private
    * @returns {void}
    */
    _onShortcutToggleVideoQuality() {
        sendAnalytics(createShortcutEvent('video.quality'));
        this._doToggleVideoQuality();
    }
    /**
     * Dispatches an action for toggling the tile view.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleTileView() {
        sendAnalytics(createShortcutEvent('toggle.tileview', ACTION_SHORTCUT_TRIGGERED, {
            enable: !this.props._tileViewEnabled
        }));
        this._doToggleTileView();
    }
    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling full screen mode.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleFullScreen() {
        sendAnalytics(createShortcutEvent('toggle.fullscreen', ACTION_SHORTCUT_TRIGGERED, {
            enable: !this.props._fullScreen
        }));
        this._doToggleFullScreen();
    }
    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling raise hand.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleRaiseHand() {
        sendAnalytics(createShortcutEvent('toggle.raise.hand', ACTION_SHORTCUT_TRIGGERED, { enable: !this.props._raisedHand }));
        this._doToggleRaiseHand();
    }
    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling screensharing.
     *
     * @private
     * @returns {void}
     */
    _onShortcutToggleScreenshare() {
        // Ignore the shortcut if the button is disabled.
        if (this.props._desktopSharingButtonDisabled) {
            return;
        }
        sendAnalytics(createShortcutEvent('toggle.screen.sharing', ACTION_SHORTCUT_TRIGGERED, {
            enable: !this.props._screenSharing
        }));
        this._doToggleScreenshare();
    }
    /**
     * Creates an analytics keyboard shortcut event and dispatches an action for
     * toggling speaker stats.
     *
     * @private
     * @returns {void}
     */
    _onShortcutSpeakerStats() {
        sendAnalytics(createShortcutEvent('speaker.stats'));
        this._doToggleSpekearStats();
    }
    /**
     * Dispatches an action to toggle speakerStats.
     *
     * @private
     * @returns {void}
     */
    _doToggleSpekearStats() {
        const { dispatch } = this.props;
        dispatch(toggleDialog(SpeakerStats, {
            conference: APP.conference
        }));
    }
    /**
     * Toggle the toolbar visibility when tabbing into it.
     *
     * @returns {void}
     */
    _onTabIn() {
        if (!this.props._visible) {
            this.props.dispatch(showToolbox());
        }
    }
    /**
     * Dispatches an action for toggling the participants pane.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleParticipantsPane() {
        const { dispatch, _participantsPaneOpen } = this.props;
        if (_participantsPaneOpen) {
            dispatch(closeParticipantsPane());
        }
        else {
            dispatch(openParticipantsPane());
        }
    }
    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * open the video quality dialog.
     *
     * @private
     * @returns {void}
     */
    _onToolbarOpenVideoQuality() {
        sendAnalytics(createToolbarEvent('video.quality'));
        this._doOpenVideoQuality();
    }
    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * the display of chat.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleChat() {
        sendAnalytics(createToolbarEvent('toggle.chat', {
            enable: !this.props._chatOpen
        }));
        this._closeOverflowMenuIfOpen();
        this._doToggleChat();
    }
    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * full screen mode.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleFullScreen() {
        sendAnalytics(createToolbarEvent('toggle.fullscreen', {
            enable: !this.props._fullScreen
        }));
        this._closeOverflowMenuIfOpen();
        this._doToggleFullScreen();
    }
    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * raise hand.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleRaiseHand() {
        sendAnalytics(createToolbarEvent('raise.hand', { enable: !this.props._raisedHand }));
        this._doToggleRaiseHand();
    }
    /**
     * Creates an analytics toolbar event and dispatches an action for toggling
     * screensharing.
     *
     * @private
     * @returns {void}
     */
    _onToolbarToggleScreenshare() {
        sendAnalytics(createToolbarEvent('toggle.screen.sharing', { enable: !this.props._screenSharing }));
        this._closeOverflowMenuIfOpen();
        this._doToggleScreenshare();
    }
    /**
     * Returns true if the audio sharing button should be visible and
     * false otherwise.
     *
     * @returns {boolean}
     */
    _showAudioSharingButton() {
        const { _desktopSharingEnabled } = this.props;
        return _desktopSharingEnabled && isScreenAudioSupported();
    }
    /**
     * Returns true if the desktop sharing button should be visible and
     * false otherwise.
     *
     * @returns {boolean}
     */
    _showDesktopSharingButton() {
        return this.props._desktopSharingEnabled;
    }
    /**
     * Returns true if the embed meeting button is visible and false otherwise.
     *
     * @returns {boolean}
     */
    _isEmbedMeetingVisible() {
        return !this.props._isVpaasMeeting
            && !this.props._isMobile;
    }
    /**
     * Returns true if the profile button is visible and false otherwise.
     *
     * @returns {boolean}
     */
    _isProfileVisible() {
        return !this.props._isProfileDisabled;
    }
    /**
     * Renders the toolbox content.
     *
     * @returns {ReactElement}
     */
    _renderToolboxContent() {
        const { _endConferenceSupported, _hangupMenuVisible, _isMobile, _isNarrowLayout, _overflowDrawer, _overflowMenuVisible, _reactionsEnabled, _reactionsButtonEnabled, _toolbarButtons, classes, t } = this.props;
        const toolbarAccLabel = 'toolbar.accessibilityLabel.moreActionsMenu';
        const containerClassName = `toolbox-content${_isMobile || _isNarrowLayout ? ' toolbox-content-mobile' : ''}`;
        const { mainMenuButtons, overflowMenuButtons } = this._getVisibleButtons();
        const raiseHandInOverflowMenu = overflowMenuButtons.some(({ key }) => key === 'raisehand');
        const showReactionsInOverflowMenu = (_reactionsEnabled && !_reactionsButtonEnabled
            && (raiseHandInOverflowMenu || _isNarrowLayout || _isMobile))
            || overflowMenuButtons.some(({ key }) => key === 'reactions');
        const showRaiseHandInReactionsMenu = showReactionsInOverflowMenu && raiseHandInOverflowMenu;
        return (React.createElement("div", { className: containerClassName },
            React.createElement("div", { className: 'toolbox-content-wrapper', onFocus: this._onTabIn, ...(_isMobile ? {} : {
                    onMouseOut: this._onMouseOut,
                    onMouseOver: this._onMouseOver
                }) },
                React.createElement("div", { className: 'toolbox-content-items', ref: this._toolboxRef },
                    mainMenuButtons.map(({ Content, key, ...rest }) => Content !== Separator && (React.createElement(Content, { ...rest, buttonKey: key, key: key }))),
                    Boolean(overflowMenuButtons.length) && (React.createElement(OverflowMenuButton, { ariaControls: 'overflow-menu', buttons: overflowMenuButtons.reduce((acc, val) => {
                            if (val.key === 'reactions' && showReactionsInOverflowMenu) {
                                return acc;
                            }
                            if (val.key === 'raisehand' && showRaiseHandInReactionsMenu) {
                                return acc;
                            }
                            if (acc.length) {
                                const prev = acc[acc.length - 1];
                                const group = prev[prev.length - 1].group;
                                if (group === val.group) {
                                    prev.push(val);
                                }
                                else {
                                    acc.push([val]);
                                }
                            }
                            else {
                                acc.push([val]);
                            }
                            return acc;
                        }, []), isOpen: _overflowMenuVisible, key: 'overflow-menu', onToolboxEscKey: this._onEscKey, onVisibilityChange: this._onSetOverflowVisible, showRaiseHandInReactionsMenu: showRaiseHandInReactionsMenu, showReactionsMenu: showReactionsInOverflowMenu })),
                    isToolbarButtonEnabled('hangup', _toolbarButtons) && (_endConferenceSupported
                        ? React.createElement(HangupMenuButton, { ariaControls: 'hangup-menu', isOpen: _hangupMenuVisible, key: 'hangup-menu', notifyMode: this._getButtonNotifyMode('hangup-menu'), onVisibilityChange: this._onSetHangupVisible },
                            React.createElement(ContextMenu, { accessibilityLabel: t(toolbarAccLabel), className: classes.hangupMenu, hidden: false, inDrawer: _overflowDrawer, onKeyDown: this._onEscKey },
                                React.createElement(EndConferenceButton, { buttonKey: 'end-meeting', notifyMode: this._getButtonNotifyMode('end-meeting') }),
                                React.createElement(LeaveConferenceButton, { buttonKey: 'hangup', notifyMode: this._getButtonNotifyMode('hangup') })))
                        : React.createElement(HangupButton, { buttonKey: 'hangup', customClass: 'hangup-button', key: 'hangup-button', notifyMode: this._getButtonNotifyMode('hangup'), visible: isToolbarButtonEnabled('hangup', _toolbarButtons) }))))));
    }
}
/**
 * Maps (parts of) the redux state to {@link Toolbox}'s React {@code Component}
 * props.
 *
 * @param {Object} state - The redux store/state.
 * @param {Object} ownProps - The props explicitly passed.
 * @private
 * @returns {{}}
 */
function _mapStateToProps(state, ownProps) {
    const { conference } = state['features/base/conference'];
    const { isNarrowLayout } = state['features/base/responsive-ui'];
    const endConferenceSupported = conference?.isEndConferenceSupported() && isLocalParticipantModerator(state);
    const { callStatsID, customToolbarButtons, disableProfile, iAmRecorder, iAmSipGateway } = state['features/base/config'];
    const { fullScreen, hangupMenuVisible, overflowMenuVisible, overflowDrawer } = state['features/toolbox'];
    const localParticipant = getLocalParticipant(state);
    const localVideo = getLocalVideoTrack(state['features/base/tracks']);
    const { clientWidth } = state['features/base/responsive-ui'];
    let toolbarButtons = ownProps.toolbarButtons || getToolbarButtons(state);
    const _reactionsEnabled = isReactionsEnabled(state);
    if (iAmVisitor(state)) {
        toolbarButtons = VISITORS_MODE_BUTTONS.filter(e => toolbarButtons.indexOf(e) > -1);
    }
    return {
        _backgroundType: state['features/virtual-background'].backgroundType ?? '',
        _buttonsWithNotifyClick: getButtonsWithNotifyClick(state),
        _chatOpen: state['features/chat'].isOpen,
        _clientWidth: clientWidth,
        _conference: conference,
        _customToolbarButtons: customToolbarButtons,
        _desktopSharingEnabled: JitsiMeetJS.isDesktopSharingEnabled(),
        _desktopSharingButtonDisabled: isDesktopShareButtonDisabled(state),
        _dialog: Boolean(state['features/base/dialog'].component),
        _disabled: Boolean(iAmRecorder || iAmSipGateway),
        _endConferenceSupported: Boolean(endConferenceSupported),
        _feedbackConfigured: Boolean(callStatsID),
        _fullScreen: fullScreen,
        _gifsEnabled: isGifEnabled(state),
        _isProfileDisabled: Boolean(disableProfile),
        _isIosMobile: isIosMobileBrowser(),
        _isMobile: isMobileBrowser(),
        _isSpeakerStatsDisabled: isSpeakerStatsDisabled(state),
        _isVpaasMeeting: isVpaasMeeting(state),
        _jwtDisabledButons: getJwtDisabledButtons(state),
        _hasSalesforce: isSalesforceEnabled(state),
        _hangupMenuVisible: hangupMenuVisible,
        _isNarrowLayout: isNarrowLayout,
        _localParticipantID: localParticipant?.id,
        _localVideo: localVideo,
        _multiStreamModeEnabled: getMultipleVideoSendingSupportFeatureFlag(state),
        _overflowMenuVisible: overflowMenuVisible,
        _overflowDrawer: overflowDrawer,
        _participantsPaneOpen: getParticipantsPaneOpen(state),
        _raisedHand: hasRaisedHand(localParticipant),
        _reactionsButtonEnabled: isReactionsButtonEnabled(state),
        _reactionsEnabled,
        _screenSharing: isScreenVideoShared(state),
        _shiftUp: state['features/toolbox'].shiftUp,
        _shortcutsEnabled: areKeyboardShortcutsEnabled(state),
        _tileViewEnabled: shouldDisplayTileView(state),
        _toolbarButtons: toolbarButtons,
        _virtualSource: state['features/virtual-background'].virtualSource,
        _visible: isToolboxVisible(state),
        _whiteboardEnabled: isWhiteboardButtonVisible(state)
    };
}
export default translate(connect(_mapStateToProps)(withStyles(styles)(Toolbox)));
