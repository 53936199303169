import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import { IconRaiseHand } from '../../../base/icons/svg';
import { getLocalParticipant, hasRaisedHand } from '../../../base/participants/functions';
import AbstractButton from '../../../base/toolbox/components/AbstractButton';
/**
 * Implementation of a button for raising hand.
 */
class RaiseHandButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.raiseHand';
        this.toggledAccessibilityLabel = 'toolbar.accessibilityLabel.lowerHand';
        this.icon = IconRaiseHand;
        this.label = 'toolbar.raiseHand';
        this.toggledLabel = 'toolbar.lowerYourHand';
        this.tooltip = 'toolbar.raiseHand';
        this.toggledTooltip = 'toolbar.lowerYourHand';
    }
    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props.raisedHand;
    }
}
/**
 * Function that maps parts of Redux state tree into component props.
 *
 * @param {Object} state - Redux state.
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    const localParticipant = getLocalParticipant(state);
    return {
        raisedHand: hasRaisedHand(localParticipant)
    };
};
export default translate(connect(mapStateToProps)(RaiseHandButton));
