import { withStyles } from '@mui/styles';
import React from 'react';
import AbstractDialogTab from '../../../base/dialog/components/web/AbstractDialogTab';
import { translate } from '../../../base/i18n/functions';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
import Checkbox from '../../../base/ui/components/web/Checkbox';
const styles = (theme) => {
    return {
        container: {
            display: 'flex',
            width: '100%',
            '@media (max-width: 607px)': {
                flexDirection: 'column'
            }
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            '&:first-child:not(:last-child)': {
                marginRight: theme.spacing(3),
                '@media (max-width: 607px)': {
                    marginRight: 0,
                    marginBottom: theme.spacing(3)
                }
            }
        },
        title: {
            ...withPixelLineHeight(theme.typography.heading6),
            color: `${theme.palette.text01} !important`,
            marginBottom: theme.spacing(3)
        },
        checkbox: {
            marginBottom: theme.spacing(3)
        }
    };
};
/**
 * React {@code Component} for modifying the local user's sound settings.
 *
 * @augments Component
 */
class NotificationsTab extends AbstractDialogTab {
    /**
     * Initializes a new {@code SoundsTab} instance.
     *
     * @param {IProps} props - The React {@code Component} props to initialize
     * the new {@code SoundsTab} instance with.
     */
    constructor(props) {
        super(props);
        // Bind event handlers so they are only bound once for every instance.
        this._onChange = this._onChange.bind(this);
        this._onEnabledNotificationsChanged = this._onEnabledNotificationsChanged.bind(this);
    }
    /**
     * Changes a sound setting state.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onChange({ target }) {
        super._onChange({ [target.name]: target.checked });
    }
    /**
     * Callback invoked to select if the given type of
     * notifications should be shown.
     *
     * @param {Object} e - The key event to handle.
     * @param {string} type - The type of the notification.
     *
     * @returns {void}
     */
    _onEnabledNotificationsChanged({ target: { checked } }, type) {
        super._onChange({
            enabledNotifications: {
                ...this.props.enabledNotifications,
                [type]: checked
            }
        });
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { classes, disabledSounds, enabledNotifications, showNotificationsSettings, showSoundsSettings, soundsIncomingMessage, soundsParticipantJoined, soundsParticipantKnocking, soundsParticipantLeft, soundsTalkWhileMuted, soundsReactions, enableReactions, moderatorMutedSoundsReactions, t } = this.props;
        return (React.createElement("div", { className: classes.container, key: 'sounds' },
            showSoundsSettings && (React.createElement("div", { className: classes.column },
                React.createElement("h2", { className: classes.title }, t('settings.playSounds')),
                React.createElement(Checkbox, { checked: soundsIncomingMessage && !disabledSounds.includes('INCOMING_MSG_SOUND'), className: classes.checkbox, disabled: disabledSounds.includes('INCOMING_MSG_SOUND'), label: t('settings.incomingMessage'), name: 'soundsIncomingMessage', onChange: this._onChange }),
                React.createElement(Checkbox, { checked: soundsParticipantJoined
                        && !disabledSounds.includes('PARTICIPANT_JOINED_SOUND'), className: classes.checkbox, disabled: disabledSounds.includes('PARTICIPANT_JOINED_SOUND'), label: t('settings.participantJoined'), name: 'soundsParticipantJoined', onChange: this._onChange }),
                React.createElement(Checkbox, { checked: soundsParticipantLeft && !disabledSounds.includes('PARTICIPANT_LEFT_SOUND'), className: classes.checkbox, disabled: disabledSounds.includes('PARTICIPANT_LEFT_SOUND'), label: t('settings.participantLeft'), name: 'soundsParticipantLeft', onChange: this._onChange }),
                React.createElement(Checkbox, { checked: soundsTalkWhileMuted && !disabledSounds.includes('TALK_WHILE_MUTED_SOUND'), className: classes.checkbox, disabled: disabledSounds.includes('TALK_WHILE_MUTED_SOUND'), label: t('settings.talkWhileMuted'), name: 'soundsTalkWhileMuted', onChange: this._onChange }),
                React.createElement(Checkbox, { checked: soundsParticipantKnocking
                        && !disabledSounds.includes('KNOCKING_PARTICIPANT_SOUND'), className: classes.checkbox, disabled: disabledSounds.includes('KNOCKING_PARTICIPANT_SOUND'), label: t('settings.participantKnocking'), name: 'soundsParticipantKnocking', onChange: this._onChange }))),
            showNotificationsSettings && (React.createElement("div", { className: classes.column },
                React.createElement("h2", { className: classes.title }, t('notify.displayNotifications')),
                Object.keys(enabledNotifications).map(key => (React.createElement(Checkbox, { checked: Boolean(enabledNotifications[key]), className: classes.checkbox, key: key, label: t(key), name: `show-${key}`, 
                    /* eslint-disable-next-line react/jsx-no-bind */
                    onChange: e => this._onEnabledNotificationsChanged(e, key) })))))));
    }
}
export default withStyles(styles)(translate(NotificationsTab));
